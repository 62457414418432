var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-wrap"
  }, [_c('div', {
    staticClass: "page"
  }, [_vm._m(0), _c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "big-title"
  }, [_vm._v("META APES")]), _c('div', {
    staticClass: "sub-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/worldIcon.png"),
      "alt": ""
    }
  }), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.jumpUrl('https://metaapesgame.com/');
      }
    }
  }, [_vm._v("https://metaapesgame.com/")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Web3go provides abundant data analysis for Meta Apes. Through the dashboard, users can check out the latest key data and go though historical data by protocal type and other different perspectives. ")])]), _c('div', {
    staticClass: "tab-wrap"
  }, [_c('div', {
    staticClass: "tabs"
  }, [_c('div', {
    staticClass: "tab-item",
    class: {
      active: _vm.$route.query.tabIndex == 1
    },
    on: {
      "click": function ($event) {
        return _vm.clickTab(1);
      }
    }
  }, [_vm._v(" Overview ")]), _c('div', {
    staticClass: "tab-item",
    class: {
      active: _vm.$route.query.tabIndex == 2
    },
    on: {
      "click": function ($event) {
        return _vm.clickTab(2);
      }
    }
  }, [_vm._v(" ERC20 ")]), _c('div', {
    staticClass: "tab-item",
    class: {
      active: _vm.$route.query.tabIndex == 3
    },
    on: {
      "click": function ($event) {
        return _vm.clickTab(3);
      }
    }
  }, [_vm._v(" ERC721 ")])])]), _c('div', {
    staticClass: "tab-content"
  }, [_vm.$route.query.tabIndex == 1 ? _c('Overview') : _vm._e(), _vm.$route.query.tabIndex == 2 ? _c('ERC20') : _vm._e(), _vm.$route.query.tabIndex == 3 ? _c('ERC721') : _vm._e()], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "banner"
  }, [_c('div', {
    staticClass: "circle"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/basicon.png"),
      "alt": ""
    }
  })])]);
}];
export { render, staticRenderFns };