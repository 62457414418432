var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content address-tag-manage"
  }, [_c('div', {
    staticClass: "top-operation"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handleNew();
      }
    }
  }, [_vm._v("New address tag")])], 1), _c('div', {}, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.addressTagList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "address",
      "label": "address",
      "width": "400"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "addressTag",
      "label": "address tag",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "description",
      "label": "description"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "operation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Edit")]), _c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.handleDelete(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Remove")])];
      }
    }])
  })], 1)], 1), _c('el-dialog', {
    attrs: {
      "title": _vm.dialogTitle,
      "visible": _vm.dialogVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("address")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    attrs: {
      "disabled": _vm.dialogTitle == 'Edit'
    },
    model: {
      value: _vm.currentRow.address,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "address", $$v);
      },
      expression: "currentRow.address"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("address tag")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "if you have multiple tags, please use comma between them"
    },
    model: {
      value: _vm.currentRow.addressTag,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "addressTag", $$v);
      },
      expression: "currentRow.addressTag"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("show tag instead of address")]), _c('div', {
    staticClass: "value"
  }, [_c('el-switch', {
    model: {
      value: _vm.currentRow.showTagInsteadOfAddress,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "showTagInsteadOfAddress", $$v);
      },
      expression: "currentRow.showTagInsteadOfAddress"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("description")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.simpleDescription,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "simpleDescription", $$v);
      },
      expression: "currentRow.simpleDescription"
    }
  })], 1)])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onSave();
      }
    }
  }, [_vm._v("Save")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };