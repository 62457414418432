var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content wallet-profiler-label-setting"
  }, [_c('div', {
    staticClass: "mask",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "main"
  }, [_c('img', {
    staticClass: "close",
    attrs: {
      "src": require("@/assets/images/close.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "g-wrap"
  }, [_c('div', {
    staticClass: "head-title"
  }, [_vm._v("Label Customization for")]), _c('div', {
    staticClass: "switch-chain"
  }, [_c('div', [_c('el-select', {
    attrs: {
      "placeholder": "choose token",
      "size": "small"
    },
    on: {
      "change": _vm.updateCurrentConfig
    },
    model: {
      value: _vm.selectedSymbol,
      callback: function ($$v) {
        _vm.selectedSymbol = $$v;
      },
      expression: "selectedSymbol"
    }
  }, _vm._l(_vm.chainList4Select, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1)]), _c('div', {
    staticClass: "card-top"
  }, [_c('div', {
    staticClass: "section-card card-top-left hb"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("High Balance")]), _c('div', {
    staticClass: "config-item"
  }, [_c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('el-radio', {
    attrs: {
      "label": "amount"
    },
    on: {
      "change": _vm.onBalanceActiveChange
    },
    model: {
      value: _vm.balanceActive,
      callback: function ($$v) {
        _vm.balanceActive = $$v;
      },
      expression: "balanceActive"
    }
  }, [_vm._v("Amount greater than ")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.amountForBalance.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('div', [_c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "size": "mini",
      "precision": 0,
      "step": 1
    },
    model: {
      value: _vm.currentSymbolConfig.amountForBalance.value,
      callback: function ($$v) {
        _vm.$set(_vm.currentSymbolConfig.amountForBalance, "value", $$v);
      },
      expression: "currentSymbolConfig.amountForBalance.value"
    }
  })], 1)]), _c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('el-radio', {
    attrs: {
      "label": "percentage"
    },
    on: {
      "change": _vm.onBalanceActiveChange
    },
    model: {
      value: _vm.balanceActive,
      callback: function ($$v) {
        _vm.balanceActive = $$v;
      },
      expression: "balanceActive"
    }
  }, [_vm._v("Top Percentage")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.perForBalance.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "size": "mini",
      "precision": 3,
      "step": 0.001
    },
    model: {
      value: _vm.currentSymbolConfig.perForBalance.value,
      callback: function ($$v) {
        _vm.$set(_vm.currentSymbolConfig.perForBalance, "value", $$v);
      },
      expression: "currentSymbolConfig.perForBalance.value"
    }
  })], 1)])]), _c('div', {
    staticClass: "section-card card-top-right ha"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("High Activity")]), _c('div', {
    staticClass: "config-item"
  }, [_c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("Top Percentage")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.perForActivity.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "size": "mini",
      "precision": 3,
      "step": 0.001
    },
    model: {
      value: _vm.currentSymbolConfig.perForActivity.value,
      callback: function ($$v) {
        _vm.$set(_vm.currentSymbolConfig.perForActivity, "value", $$v);
      },
      expression: "currentSymbolConfig.perForActivity.value"
    }
  })], 1), _c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("Time Period")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.perForActivity.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-date-picker', {
    staticClass: "date-picker",
    attrs: {
      "type": "daterange",
      "range-separator": ""
    },
    on: {
      "change": _vm.update_startDateForHighActivity
    },
    model: {
      value: _vm.timePeriod4highActivity,
      callback: function ($$v) {
        _vm.timePeriod4highActivity = $$v;
      },
      expression: "timePeriod4highActivity"
    }
  })], 1)])])]), _c('div', {
    staticClass: "section-card sh card-bottom"
  }, [_c('div', {
    staticClass: "card-bottom-left"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Strong Holder")]), _c('div', {
    staticClass: "stitle"
  }, [_vm._v("Balance")]), _c('div', {
    staticClass: "config-item"
  }, [_c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('el-radio', {
    attrs: {
      "label": "amount"
    },
    on: {
      "change": _vm.onBalanceActive4shChange
    },
    model: {
      value: _vm.balanceActive4sh,
      callback: function ($$v) {
        _vm.balanceActive4sh = $$v;
      },
      expression: "balanceActive4sh"
    }
  }, [_vm._v("Amount greater than")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.amountForSHOTop.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "size": "mini",
      "precision": 0,
      "step": 1
    },
    model: {
      value: _vm.currentSymbolConfig.amountForSHOTop.value,
      callback: function ($$v) {
        _vm.$set(_vm.currentSymbolConfig.amountForSHOTop, "value", $$v);
      },
      expression: "currentSymbolConfig.amountForSHOTop.value"
    }
  })], 1), _c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('el-radio', {
    attrs: {
      "label": "percentage"
    },
    on: {
      "change": _vm.onBalanceActive4shChange
    },
    model: {
      value: _vm.balanceActive4sh,
      callback: function ($$v) {
        _vm.balanceActive4sh = $$v;
      },
      expression: "balanceActive4sh"
    }
  }, [_vm._v("Top Percentage")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.perForSHOTop.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "size": "mini",
      "precision": 3,
      "step": 0.001
    },
    model: {
      value: _vm.currentSymbolConfig.perForSHOTop.value,
      callback: function ($$v) {
        _vm.$set(_vm.currentSymbolConfig.perForSHOTop, "value", $$v);
      },
      expression: "currentSymbolConfig.perForSHOTop.value"
    }
  })], 1)])]), _c('div', {
    staticClass: "card-bottom-right"
  }, [_c('div', {
    staticClass: "stitle"
  }, [_vm._v("Activity")]), _c('div', {
    staticClass: "config-item"
  }, [_c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("Bottom Percentage")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.perForSHOBottom.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "size": "mini",
      "precision": 3,
      "step": 0.001
    },
    model: {
      value: _vm.currentSymbolConfig.perForSHOBottom.value,
      callback: function ($$v) {
        _vm.$set(_vm.currentSymbolConfig.perForSHOBottom, "value", $$v);
      },
      expression: "currentSymbolConfig.perForSHOBottom.value"
    }
  })], 1), _c('div', {
    staticClass: "config-item-row"
  }, [_c('div', [_c('div', {
    staticClass: "label"
  }, [_vm._v("Time Period")]), _c('el-tooltip', {
    staticClass: "item",
    attrs: {
      "effect": "dark",
      "content": _vm.currentSymbolConfig.perForActivity.info,
      "placement": "right"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('el-date-picker', {
    staticClass: "date-picker",
    attrs: {
      "type": "daterange",
      "range-separator": ""
    },
    on: {
      "change": _vm.update_startDateForSHO
    },
    model: {
      value: _vm.timePeriod4SHOBottom,
      callback: function ($$v) {
        _vm.timePeriod4SHOBottom = $$v;
      },
      expression: "timePeriod4SHOBottom"
    }
  })], 1)])])]), _c('div', {
    staticClass: "operations"
  }, [_c('span', {
    on: {
      "click": _vm.saveConfig
    }
  }, [_vm._v("Update Config")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };