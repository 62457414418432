var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    staticClass: "img-circle img-lg",
    attrs: {
      "src": _vm.options.img,
      "title": "Upload avatar"
    },
    on: {
      "click": function ($event) {
        return _vm.editCropper();
      }
    }
  }), _c('el-dialog', {
    attrs: {
      "title": _vm.title,
      "visible": _vm.open,
      "width": "800px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.open = $event;
      },
      "opened": _vm.modalOpened
    }
  }, [_c('el-row', [_c('el-col', {
    style: {
      height: '350px'
    },
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_vm.visible ? _c('vue-cropper', {
    ref: "cropper",
    attrs: {
      "img": _vm.options.img,
      "info": true,
      "autoCrop": _vm.options.autoCrop,
      "autoCropWidth": _vm.options.autoCropWidth,
      "autoCropHeight": _vm.options.autoCropHeight,
      "fixedBox": _vm.options.fixedBox
    },
    on: {
      "realTime": _vm.realTime
    }
  }) : _vm._e()], 1), _c('el-col', {
    style: {
      height: '350px'
    },
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', {
    staticClass: "avatar-upload-preview"
  }, [_c('img', {
    style: _vm.previews.img,
    attrs: {
      "src": _vm.previews.url
    }
  })])])], 1), _c('br'), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 2,
      "md": 2
    }
  }, [_c('el-upload', {
    staticClass: "btn-wrap",
    attrs: {
      "action": "#",
      "http-request": _vm.requestUpload,
      "show-file-list": false,
      "before-upload": _vm.beforeUpload
    }
  }, [_c('el-button', {
    staticClass: "btn",
    attrs: {
      "size": "small"
    }
  }, [_vm._v(" Upload "), _c('i', {
    staticClass: "el-icon-upload el-icon--right"
  })])], 1)], 1), _c('el-col', {
    attrs: {
      "lg": {
        span: 1,
        offset: 2
      },
      "md": 2
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "el-icon-plus",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.changeScale(1);
      }
    }
  })], 1), _c('el-col', {
    attrs: {
      "lg": {
        span: 1,
        offset: 1
      },
      "md": 2
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "el-icon-minus",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.changeScale(-1);
      }
    }
  })], 1), _c('el-col', {
    attrs: {
      "lg": {
        span: 1,
        offset: 1
      },
      "md": 2
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "el-icon-refresh-left",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.rotateLeft();
      }
    }
  })], 1), _c('el-col', {
    attrs: {
      "lg": {
        span: 1,
        offset: 1
      },
      "md": 2
    }
  }, [_c('el-button', {
    attrs: {
      "icon": "el-icon-refresh-right",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.rotateRight();
      }
    }
  })], 1), _c('el-col', {
    attrs: {
      "lg": {
        span: 2,
        offset: 6
      },
      "md": 2
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": function ($event) {
        return _vm.uploadImg();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };