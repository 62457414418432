var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout nft-breakdown-page"
  }, [_c('div', {
    staticClass: "item-big-title"
  }, [_c('span', {
    staticClass: "label hover-item",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v("Item List")]), _c('i', {
    staticClass: "el-icon-arrow-right"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.name))])]), _c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "left-title"
  }, [_vm._v(" " + _vm._s(_vm.infoData.name) + " ")]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Item ID")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.id))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Collection")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.collectionId))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Creator")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.issuer))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Owner")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.currentOwnerAccount))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Metadata")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.metadata))])])]), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "img",
    style: {
      'background-image': 'url(' + _vm.ntfLink + ')'
    }
  })])]), _c('div', {
    staticClass: "chart-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chartLoading,
      expression: "chartLoading"
    }],
    staticClass: "chart-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" NFT Trades Over Time ")]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover",
      "content": "Track the specific item trades date and price"
    }
  }, [_c('img', {
    staticClass: "info-icon",
    attrs: {
      "src": require("@/assets/images/info-icon.png")
    }
  })])], 1), _c('div', {
    ref: "chart1",
    staticClass: "chart chart1"
  })]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chartLoading,
      expression: "chartLoading"
    }],
    staticClass: "chart-item table"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" NFT Activities Over Time ")]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover",
      "content": "Track all interactions of item order by time"
    }
  }, [_c('img', {
    staticClass: "info-icon",
    attrs: {
      "src": require("@/assets/images/info-icon.png")
    }
  })])], 1), _c('div', {
    staticClass: "table-wrap"
  }, [_c('el-table', {
    attrs: {
      "data": _vm.smallTableData,
      "height": "390"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Time",
      "sortable": "",
      "sort-method": _vm.sortTime
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-tooltip', {
          attrs: {
            "placement": "top",
            "trigger": "hover",
            "content": scope.row.timestamp
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$moment(scope.row.timestamp).fromNow()) + " ")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "From"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('identity-icon-plus', {
          attrs: {
            "addressInfo": {
              address: scope.row.interactionAccount,
              addressDisplayCompact: true,
              iconSize: 18,
              fontSize: 12
            }
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "To"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('identity-icon-plus', {
          attrs: {
            "addressInfo": {
              address: _vm.getToAccount(scope.row),
              addressDisplayCompact: true,
              iconSize: 18,
              fontSize: 12
            }
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "interaction",
      "label": "Interaction",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "width": "110",
      "label": "Price(KSM)",
      "sortable": "",
      "sort-method": _vm.sortPrice
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(scope.row.nftPrice)) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "100",
      "label": "BlockNumber"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "hover-item block-number",
          on: {
            "click": function ($event) {
              return _vm.clickBlockNumber(scope.row.blockNumber);
            }
          }
        }, [_vm._v(_vm._s(scope.row.blockNumber) + " ")])];
      }
    }])
  })], 1)], 1)])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };