var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "component-wrap"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "num"
  }, [_vm.numData.block_number !== undefined ? _c('countTo', {
    attrs: {
      "startVal": 0,
      "endVal": _vm.numData.block_number,
      "duration": 3000
    }
  }) : _c('span', [_vm._v("--")])], 1), _c('span', {
    staticClass: "label"
  }, [_vm._v("Blocks")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "num"
  }, [_vm.numData.total_transactions !== undefined ? _c('countTo', {
    attrs: {
      "startVal": 0,
      "endVal": _vm.numData.total_transactions,
      "duration": 3000
    }
  }) : _c('span', [_vm._v("--")])], 1), _c('span', {
    staticClass: "label"
  }, [_vm._v("Total Transactions")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "num"
  }, [_vm.numData.total_addresses !== undefined ? _c('countTo', {
    attrs: {
      "startVal": 0,
      "endVal": _vm.numData.total_addresses,
      "duration": 3000
    }
  }) : _c('span', [_vm._v("--")])], 1), _c('span', {
    staticClass: "label"
  }, [_vm._v("Total Addresses")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "num"
  }, [_vm.numData.total_gas !== undefined ? _c('countTo', {
    attrs: {
      "decimals": 2,
      "startVal": 0,
      "endVal": _vm.numData.total_gas,
      "duration": 3000
    }
  }) : _c('span', [_vm._v("--")])], 1), _c('span', {
    staticClass: "label"
  }, [_vm._v("Total Gas")])])])]), _vm._m(0), _vm._m(1), _vm._m(2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/084ca9f1-fd6b-43a1-800a-a4978513d1be",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/5089ad48-8bad-4a77-a41d-5f503fdc7f39",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/224d0107-f665-423e-b60b-44ef0cd85e3e",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })]);
}];
export { render, staticRenderFns };