var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "login-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('img', {
    staticClass: "left-bg",
    attrs: {
      "src": require("@/assets/images/login/bg1.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "login-main"
  }, [_vm._m(0), _c('div', {
    staticClass: "title"
  }, [_vm._v("Sign Up")]), _c('el-form', {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      "label-position": "top",
      "model": _vm.loginForm,
      "rules": _vm.loginRules
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "username",
      "label": "Your name"
    }
  }, [_c('el-input', {
    attrs: {
      "suffix-icon": "el-icon-user-solid",
      "type": "text",
      "auto-complete": "off"
    },
    model: {
      value: _vm.loginForm.username,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "username", $$v);
      },
      expression: "loginForm.username"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "email",
      "label": "Your Email"
    }
  }, [_c('el-input', {
    attrs: {
      "suffix-icon": "el-icon-user",
      "type": "text",
      "auto-complete": "off"
    },
    model: {
      value: _vm.loginForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "email", $$v);
      },
      expression: "loginForm.email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "prop": "password",
      "label": "Password"
    }
  }, [_c('el-input', {
    attrs: {
      "suffix-icon": "el-icon-lock",
      "type": "password",
      "auto-complete": "off"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleRegister.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('el-button', {
    attrs: {
      "loading": _vm.loading,
      "size": "big",
      "type": "primary"
    },
    nativeOn: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.handleRegister.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "login-txt"
  }, [_vm._v("Create account")])])], 1)], 1)], 1)]), _vm._m(1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "l-logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/login/logo@2x.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right"
  }, [_c('img', {
    staticClass: "bg",
    attrs: {
      "src": require("@/assets/images/login/bg3.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "bg2"
  })]);
}];
export { render, staticRenderFns };