var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "homepage"
  }, [_c('div', {
    staticClass: "slide"
  }, [_c('ul', {
    staticClass: "fSort"
  }, _vm._l(_vm.slideList, function (fItem, findex) {
    return _c('li', {
      key: findex,
      staticClass: "fItem"
    }, [_c('div', {
      staticClass: "name fname",
      class: {
        active: _vm.defaultActiveSlideIndex === findex
      },
      on: {
        "click": function ($event) {
          return _vm.clickSlide(fItem, findex);
        }
      }
    }, [_c('span', {
      staticClass: "icon",
      class: fItem.name
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(fItem.name))]), fItem.children && fItem.children.length ? _c('span', {
      staticClass: "arrow",
      class: {
        hide: fItem.isShow
      }
    }) : _vm._e()]), fItem.children && fItem.children.length && fItem.isShow ? _c('ul', {
      staticClass: "sSort"
    }, _vm._l(fItem.children, function (sItem, sindex) {
      return _c('li', {
        key: `${findex}${sindex}`,
        staticClass: "sItem"
      }, [_c('div', {
        staticClass: "name sname",
        class: {
          active: _vm.defaultActiveSlideIndex === `${findex}-${sindex}`
        },
        on: {
          "click": function ($event) {
            return _vm.clickSlide(sItem, `${findex}-${sindex}`);
          }
        }
      }, [_c('span', {
        staticClass: "icon"
      }), _c('span', {
        staticClass: "text"
      }, [_vm._v(_vm._s(sItem.name))])])]);
    }), 0) : _vm._e()]);
  }), 0)]), _c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "case-wrapper clearfix"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.featuredCardVisible,
      expression: "featuredCardVisible"
    }],
    staticClass: "card"
  }, [_c('h2', {
    staticClass: "card-title"
  }, [_vm._v("Staking")]), _c('div', {
    staticClass: "card-content"
  }, _vm._l(_vm.monriverDashboardListFilter, function (d) {
    return _c('div', {
      key: d.id,
      staticClass: "card-item",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail(d.type, d);
        }
      }
    }, [_c('div', {
      staticClass: "card-item-head"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": d.icon,
        "alt": ""
      }
    }), _c('span', {
      staticClass: "type"
    }, [_vm._v(_vm._s(_vm.mapCardType(d.type)))])]), _c('div', {
      staticClass: "card-item-con"
    }, [_c('img', {
      staticClass: "polyline",
      attrs: {
        "src": _vm.getCardLineImg(d.type),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "card-item-foot"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(d.name))]), d.viewCount ? _c('p', {
      staticClass: "clickNum"
    }, [_c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userInfo,
        expression: "userInfo"
      }],
      class: {
        'el-icon-star-on': _vm.ifAlreadyCollect(d),
        'el-icon-star-off': !_vm.ifAlreadyCollect(d)
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collect(d);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("./../assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(d.viewCount))])]) : _vm._e()])]);
  }), 0), _c('h2', {
    staticClass: "card-title"
  }, [_vm._v("Featured")]), _c('div', {
    staticClass: "card-content"
  }, [_vm._l(_vm.rmrkDashboardListFilter, function (d) {
    return _c('div', {
      key: d.id,
      staticClass: "card-item",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail('RMRKNFT', d);
        }
      }
    }, [_c('div', {
      staticClass: "card-item-head"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": d.icon,
        "alt": ""
      }
    }), _c('span', {
      staticClass: "type"
    }, [_vm._v(_vm._s(d.name))])]), _vm._m(1, true), _c('div', {
      staticClass: "card-item-foot"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(d.name))]), d.viewCount ? _c('p', {
      staticClass: "clickNum"
    }, [_c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userInfo,
        expression: "userInfo"
      }],
      class: {
        'el-icon-star-on': _vm.ifAlreadyCollect(d),
        'el-icon-star-off': !_vm.ifAlreadyCollect(d)
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collect(d);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("./../assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(d.viewCount))])]) : _vm._e()])]);
  }), _vm._l(_vm.parachainCrowdloanDashboardListFilter, function (d) {
    return _c('div', {
      key: d.id,
      staticClass: "card-item",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail('crowdloan', d);
        }
      }
    }, [_c('div', {
      staticClass: "card-item-head"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": d.icon,
        "alt": ""
      }
    }), _c('span', {
      staticClass: "type"
    }, [_vm._v(_vm._s(_vm.mapCardType(d.type)))])]), _c('div', {
      staticClass: "card-item-con"
    }, [_c('img', {
      staticClass: "polyline",
      attrs: {
        "src": _vm.getCardLineImg(d.type),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "card-item-foot"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(d.name))]), d.viewCount ? _c('p', {
      staticClass: "clickNum"
    }, [_c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userInfo,
        expression: "userInfo"
      }],
      class: {
        'el-icon-star-on': _vm.ifAlreadyCollect(d),
        'el-icon-star-off': !_vm.ifAlreadyCollect(d)
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collect(d);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("./../assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(d.viewCount))])]) : _vm._e()])]);
  }), _vm._l(_vm.karuraDashboardListFilter, function (d) {
    return _c('div', {
      key: d.id,
      staticClass: "card-item",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail('cdp', d);
        }
      }
    }, [_c('div', {
      staticClass: "card-item-head"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": require("./../assets/images/home/karura.png"),
        "alt": ""
      }
    }), _c('span', {
      staticClass: "type"
    }, [_vm._v(_vm._s(_vm.mapCardType(d.type)))])]), _c('div', {
      staticClass: "card-item-con"
    }, [_c('img', {
      staticClass: "polyline",
      attrs: {
        "src": _vm.getCardLineImg(d.type),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "card-item-foot"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(d.name))]), d.viewCount ? _c('p', {
      staticClass: "clickNum"
    }, [_c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userInfo,
        expression: "userInfo"
      }],
      class: {
        'el-icon-star-on': _vm.ifAlreadyCollect(d),
        'el-icon-star-off': !_vm.ifAlreadyCollect(d)
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collect(d);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("./../assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(d.viewCount))])]) : _vm._e()])]);
  }), _vm._l(_vm.erc20DashboardListFilter, function (d) {
    return _c('div', {
      key: d.id,
      staticClass: "card-item",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail('erc20', d);
        }
      }
    }, [_c('div', {
      staticClass: "card-item-head"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": require("./../assets/images/home/erc20.png"),
        "alt": ""
      }
    }), _c('span', {
      staticClass: "type"
    }, [_vm._v(_vm._s(_vm.mapCardType(d.type)) + " - " + _vm._s(d.name))])]), _c('div', {
      staticClass: "card-item-con"
    }, [_c('img', {
      staticClass: "polyline",
      attrs: {
        "src": _vm.getCardLineImg(d.type),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "card-item-foot"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(d.name))]), d.viewCount ? _c('p', {
      staticClass: "clickNum"
    }, [_c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userInfo,
        expression: "userInfo"
      }],
      class: {
        'el-icon-star-on': _vm.ifAlreadyCollect(d),
        'el-icon-star-off': !_vm.ifAlreadyCollect(d)
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collect(d);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("./../assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(d.viewCount))])]) : _vm._e()])]);
  })], 2)])]), _c('div', {
    staticClass: "case-wrapper clearfix"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.communityCardVisible,
      expression: "communityCardVisible"
    }],
    staticClass: "card"
  }, [_c('h2', {
    staticClass: "card-title"
  }, [_vm._v("Community")]), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "card-item create",
    on: {
      "click": function ($event) {
        return _vm.buildNewQuery();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("./../assets/images/home/plus_create.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("Create New Analysis")])]), _vm._l(_vm.customQueryDashboardListFilter, function (d) {
    return _c('div', {
      key: d.id,
      staticClass: "card-item",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail('customQuery', d);
        }
      }
    }, [_c('div', {
      staticClass: "card-item-head"
    }, [_c('img', {
      staticClass: "logo",
      attrs: {
        "src": require("./../assets/images/home/customQuery.png"),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "type"
    }, [_vm._v(_vm._s(d.type) + _vm._s(d.name))]), _c('div', {
      staticClass: "option"
    }, [_c('img', {
      staticClass: "option-icon",
      attrs: {
        "src": require("./../assets/images/home/option.png"),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "option-con"
    }, [_c('p', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.handleCustomQueryActionCommand(d.id + '-edit');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("./../assets/images/home/edit.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v("Edit")])]), _c('p', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.handleCustomQueryActionCommand(d.id + '-remove');
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("./../assets/images/home/delete.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v("Delete")])])])])]), _c('div', {
      staticClass: "card-item-con"
    }, [_c('img', {
      staticClass: "polyline",
      attrs: {
        "src": _vm.getCardLineImg(d.type),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "card-item-foot"
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(d.name))]), d.viewCount ? _c('p', {
      staticClass: "clickNum"
    }, [_c('i', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userInfo,
        expression: "userInfo"
      }],
      class: {
        'el-icon-star-on': _vm.ifAlreadyCollect(d),
        'el-icon-star-off': !_vm.ifAlreadyCollect(d)
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.collect(d);
        }
      }
    }), _c('img', {
      attrs: {
        "src": require("./../assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(d.viewCount))])]) : _vm._e()])]);
  })], 2)])]), !_vm.featuredCardVisible && !_vm.communityCardVisible ? _c('div', {
    staticClass: "empty"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/nodata.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("No data…")])]) : _vm._e()]), _c('div', {
    staticClass: "foot"
  }, [_c('ul', _vm._l(_vm.footList, function (foot, index) {
    return _c('li', {
      key: index
    }, [_c('a', {
      attrs: {
        "href": foot.url,
        "target": "blank"
      }
    }, [foot.icon ? _c('img', {
      attrs: {
        "src": require(`../assets/images/home/${foot.icon}.png`),
        "alt": ""
      }
    }) : _c('span', [_vm._v(_vm._s(foot.name))])])]);
  }), 0)])]), _c('el-dialog', {
    attrs: {
      "title": "Confirm",
      "visible": _vm.removeCustomQueryDialogVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.removeCustomQueryDialogVisible = $event;
      }
    }
  }, [_c('span', [_vm._v("Are you sure to remove current item?")]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.removeCustomQueryDialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    on: {
      "click": _vm.doRemoveCustomQuery
    }
  }, [_vm._v("Confirm")])], 1)])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "adv-v2"
  }, [_c('img', {
    staticClass: "adv-banner-v2",
    attrs: {
      "src": require("./../assets/images/banner-v2.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-item-con"
  }, [_c('img', {
    staticClass: "polyline",
    staticStyle: {
      "width": "95%",
      "margin-top": "-30px"
    },
    attrs: {
      "src": require("./../assets/images/home/rmrk-nft-dashboard.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };