export default {
  "types": {
    "AccountId": "EthereumAccountId",
    "Address": "AccountId",
    "Balance": "u128",
    "RefCount": "u8",
    "LookupSource": "AccountId",
    "Account": {
      "nonce": "U256",
      "balance": "u128"
    },
    "RewardInfo": {
      "total_reward": "Balance",
      "claimed_reward": "Balance",
      "contributed_relay_addresses": "Vec<RelayChainAccountId>"
    },
    "VestingBlockNumber": "u32",
    "NominatorStatus": {
      "_enum": {
        "Active": "Null",
        "Leaving": "RoundIndex"
      }
    },
    "Nominator2": {
      "nominations": "Vec<Bond>",
      "revocations": "Vec<AccountId>",
      "total": "Balance",
      "scheduled_revocations_count": "u32",
      "scheduled_revocations_total": "Balance",
      "status": "NominatorStatus"
    },
    "ExitQ": {
      "candidates": "Vec<AccountId>",
      "nominators_leaving": "Vec<AccountId>",
      "candidate_schedule": "Vec<(AccountId, RoundIndex)>",
      "nominator_schedule": "Vec<(AccountId, Option<AccountId>, RoundIndex)>"
    },
    "EthereumSignature": {
      "r": "H256",
      "s": "H256",
      "v": "U8"
    },
    "Collator2": {
      "id": "AccountId",
      "bond": "Balance",
      "nominators": "Vec<AccountId>",
      "top_nominators": "Vec<Bond>",
      "bottom_nominators": "Vec<Bond>",
      "total_counted": "Balance",
      "total_backing": "Balance",
      "state": "CollatorStatus"
    },
    "NominatorAdded": {
      "_enum": {
        "AddedToTop": "Balance",
        "AddedToBottom": "Null"
      }
    },
    "RegistrationInfo": {
      "account": "AccountId",
      "deposit": "Balance"
    },
    "ParachainBondConfig": {
      "account": "AccountId",
      "percent": "Percent"
    },
    "AccountId32": "H256",
    "AuthorId": "AccountId32",
    "RelayChainAccountId": "AccountId32",
    "AccountInfo": "AccountInfoWithTripleRefCount",
    "Candidate": {
      "id": "AccountId",
      "fee": "Perbill",
      "bond": "Balance",
      "nominators": "Vec<Bond>",
      "total": "Balance",
      "state": "CollatorStatus"
    },
    "CollatorStatus": {
      "_enum": {
        "Active": "Null",
        "Idle": "Null",
        "Leaving": "RoundIndex"
      }
    },
    "Range": "RangeBalance",
    "RangeBalance": {
      "min": "Balance",
      "ideal": "Balance",
      "max": "Balance"
    },
    "RangePerbill": {
      "min": "Perbill",
      "ideal": "Perbill",
      "max": "Perbill"
    },
    "InflationInfo": {
      "expect": "RangeBalance",
      "annual": "RangePerbill",
      "round": "RangePerbill"
    },
    "OrderedSet": "Vec<Bond>",
    "Collator": {
      "id": "AccountId",
      "bond": "Balance",
      "nominators": "Vec<Bond>",
      "total": "Balance",
      "state": "CollatorStatus"
    },
    "CollatorSnapshot": {
      "bond": "Balance",
      "nominators": "Vec<Bond>",
      "total": "Balance"
    },
    "SystemInherentData": {
      "validation_data": "PersistedValidationData",
      "relay_chain_state": "StorageProof",
      "downward_messages": "Vec<InboundDownwardMessage>",
      "horizontal_messages": "BTreeMap<ParaId, Vec<InboundHrmpMessage>>"
    },
    "RoundInfo": {
      "current": "RoundIndex",
      "first": "BlockNumber",
      "length": "u32"
    },
    "ExtrinsicSignature": "EthereumSignature",
    "RoundIndex": "u32",
    "Nominator": {
      "nominations": "Vec<Bond>",
      "total": "Balance"
    },
    "Bond": {
      "owner": "AccountId",
      "amount": "Balance"
    },
    "ValidatorStatus": {
      "_enum": {
        "Active": "Null",
        "Idle": "Null",
        "Leaving": "RoundIndex"
      }
    },
    "TxPoolResultContent": {
      "pending": "HashMap<H160, HashMap<U256, PoolTransaction>>",
      "queued": "HashMap<H160, HashMap<U256, PoolTransaction>>"
    },
    "TxPoolResultInspect": {
      "pending": "HashMap<H160, HashMap<U256, Summary>>",
      "queued": "HashMap<H160, HashMap<U256, Summary>>"
    },
    "TxPoolResultStatus": {
      "pending": "U256",
      "queued": "U256"
    },
    "Summary": "Bytes",
    "PoolTransaction": {
      "hash": "H256",
      "nonce": "U256",
      "block_hash": "Option<H256>",
      "block_number": "Option<U256>",
      "from": "H160",
      "to": "Option<H160>",
      "value": "U256",
      "gas_price": "U256",
      "gas": "U256",
      "input": "Bytes"
    }
  }
};