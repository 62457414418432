var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "profile-home-page"
  }, [_c('div', {
    staticClass: "center"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" You can track balance, transactions, NFTs, and contributions of crowdloan across different parachains on Web3Go now! ")]), _c('div', {
    staticClass: "search-wrap"
  }, [_c('el-input', {
    staticClass: "input",
    attrs: {
      "placeholder": "Search address"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.jumpToIndex.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, [_c('img', {
    attrs: {
      "slot": "prefix",
      "src": require("@/assets/images/profile/search.png"),
      "alt": ""
    },
    slot: "prefix"
  })])], 1), _c('div', {
    staticClass: "recommand-addresses"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Or try these :")]), _vm._l(_vm.recommandAddresses, function (d) {
    return _c('div', {
      key: d.address,
      staticClass: "address",
      on: {
        "click": function ($event) {
          return _vm.useAddress(d);
        }
      }
    }, [_vm._v(" @" + _vm._s(d.identity) + " ")]);
  })], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };