var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content cdp-detail-page cdp-page"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ifWhiteTheme,
      expression: "!ifWhiteTheme"
    }],
    staticClass: "search-filter"
  }, [_c('div', {
    staticClass: "head-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._m(0)]), _c('div', {
    staticClass: "title1"
  }, [_vm._v("Enter CDP Account ID")]), _c('div', {
    staticClass: "title2"
  }), _c('div', [_c('el-input', {
    staticClass: "cdp-search-filter-input",
    attrs: {
      "clearable": "",
      "prefix-icon": "el-icon-search",
      "type": "text"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchLoanPositionByAccountId.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.accountId,
      callback: function ($$v) {
        _vm.$set(_vm.query, "accountId", $$v);
      },
      expression: "query.accountId"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.ifWhiteTheme,
      expression: "!ifWhiteTheme"
    }],
    staticClass: "loanPositionStatistic"
  }, [_c('div', {
    staticClass: "loanposition"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 7
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc",
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Owner")]), _c('div', {
    staticClass: "value owner-account",
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("shorterAddress")(_vm.currentLoanPosition.accountId)) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 4
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Collateral")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.collateralFormat))), _c('span', {
    staticClass: "unit"
  }, [_vm._v("KSM")])])])]), _c('el-col', {
    attrs: {
      "span": 4
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Debt")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.debitFormat))), _c('span', {
    staticClass: "unit"
  }, [_vm._v("KUSD")])])])]), _c('el-col', {
    attrs: {
      "span": 4
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_c('div', {
    staticClass: "ratio-status",
    class: _vm.currentLoanPosition.status + '-bg'
  }), _vm._v(" Ratio(Min:" + _vm._s(_vm._f("roundNumber")(_vm.chainState.liquidationRatioPercentage)) + "%) ")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.ratioPercentage)) + "% ")])])]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Current price:")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.collateralPrice)) + " ")])])])], 1)], 1), _c('div', {
    staticClass: "statistic"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Value Invested")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" $" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.valueInvested)) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Value Withdrawn")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" $" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.valueWithdraw)) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("CDP Balance")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" $" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.cdpBalance)) + " ")])])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("Lifetime Profit")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" $" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.lifetimeProfit)) + " ")])])])], 1)], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ifWhiteTheme,
      expression: "ifWhiteTheme"
    }],
    staticClass: "common-back-title",
    staticStyle: {
      "margin-left": "-100px",
      "margin-right": "-100px"
    }
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.currentLoanPosition.accountId))])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ifWhiteTheme,
      expression: "ifWhiteTheme"
    }],
    staticClass: "new-top"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Owner")]), _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.currentLoanPosition.accountId) + " ")])]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "bottom-left"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Callateral")]), _c('div', {
    staticClass: "item-content"
  }, [_c('span', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.collateralFormat)))]), _c('span', {
    staticClass: "unit"
  }, [_vm._v("KSM")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Debt")]), _c('div', {
    staticClass: "item-content"
  }, [_c('span', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.debitFormat)))]), _c('span', {
    staticClass: "unit"
  }, [_vm._v("KUSD")])])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "ratio-status",
    class: _vm.currentLoanPosition.status + '-bg'
  }), _c('span', [_vm._v(" Ratio(Min:" + _vm._s(_vm._f("roundNumber")(_vm.chainState.liquidationRatioPercentage)) + "%) ")])]), _c('div', {
    staticClass: "item-content"
  }, [_c('div', {
    staticClass: "number"
  }, [_vm._v(" " + _vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.ratioPercentage)) + "% ")])])])]), _c('div', {
    staticClass: "bottom-right"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Current price:")]), _c('div', {
    staticClass: "number"
  }, [_vm._v(" $" + _vm._s(_vm._f("roundNumber")(_vm.currentLoanPosition.collateralPrice)) + " ")])])])]), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Value Invested:")]), _c('span', {
    staticClass: "value"
  }, [_vm._v("$" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.valueInvested)))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Value Withdrawn:")]), _c('span', {
    staticClass: "value"
  }, [_vm._v("$" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.valueWithdraw)))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("CDP Balance:")]), _c('span', {
    staticClass: "value"
  }, [_vm._v("$" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.cdpBalance)))])]), _c('div', {
    staticClass: "item special"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Lifetime Profit:")]), _c('span', {
    staticClass: "value green"
  }, [_vm._v(" $" + _vm._s(_vm._f("roundNumber")(_vm.statisticData.lifetimeProfit)))])])])]), _c('div', {
    staticClass: "kLine-section"
  }, [_vm._m(1), _c('div', {
    ref: "kLineChart",
    staticClass: "kLine-chart"
  })]), _c('div', {
    staticClass: "loanaction-section"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Action List")]), _c('div', {
    staticClass: "list"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.loanActionList.list.slice((_vm.currentPage - 1) * _vm.pageSize, _vm.currentPage * _vm.pageSize)
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Activity",
      "prop": "type"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Collateral Change"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('div', [_vm._v(" " + _vm._s(_vm._f("formatKUSAMA")(props.row.collateralAdjustment)) + " KSM ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Debt Change"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('div', [_vm._v(_vm._s(props.row.debitAdjustment) + " KUSD")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Ratio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('div', [_vm._v(" " + _vm._s(props.row.ratioBefore) + " %  > " + _vm._s(props.row.ratioAfter) + "% ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('div', [_vm._v(" " + _vm._s(props.row.date) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "type": "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('span', [_vm._v(_vm._s(props.row.name))]), _c('div', {
          staticClass: "compare-card"
        }, [_c('div', {
          staticClass: "before"
        }, [_c('div', {
          staticClass: "head-title"
        }, [_vm._v("Before")]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Debt:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(props.row.debitBefore) + " KUSD ")])]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Collateral:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(props.row.collateralBefore) + " KSM ")])]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Price:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" $ " + _vm._s(props.row.collateralPrice) + " ")])]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Ratio:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(_vm._s(props.row.ratioBefore) + " %")])])]), _c('div', {
          staticClass: "after"
        }, [_c('div', {
          staticClass: "head-title"
        }, [_vm._v("After")]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Debt:")]), _c('div', {
          staticClass: "value"
        }, [_c('div', [_vm._v(_vm._s(props.row.debitAfter) + " KUSD")]), _c('div', {
          staticClass: "change",
          class: props.row.debitAdjustmentClass
        }, [_vm._v(" " + _vm._s(props.row.debitAdjustmentFormated >= 0 ? "+" : "") + " " + _vm._s(props.row.debitAdjustmentFormated) + " KUSD ")])])]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Collateral:")]), _c('div', {
          staticClass: "value"
        }, [_c('div', [_vm._v(_vm._s(props.row.collateralAfter) + " KSM")]), _c('div', {
          staticClass: "change",
          class: props.row.collateralAdjustmentClass
        }, [_vm._v(" " + _vm._s(props.row.collateralAdjustmentFormated >= 0 ? "+" : "") + " " + _vm._s(props.row.collateralAdjustmentFormated) + " KSM ")])])]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Price:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" $ " + _vm._s(props.row.collateralPrice) + " ")])]), _c('div', {
          staticClass: "section"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Ratio:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(_vm._s(props.row.ratioAfter) + " %")])])]), _c('div', {
          staticClass: "info"
        }, [_c('div', {
          staticClass: "status"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Status:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(_vm._s(props.row.status))])]), _c('div', {
          staticClass: "date"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Date:")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(_vm._s(props.row.date))])]), _c('div', {
          staticClass: "block-number"
        }, [_c('div', {
          staticClass: "desc"
        }, [_vm._v("Block Number")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(_vm._s(props.row.blockNumber))])])])])];
      }
    }])
  })], 1), _c('div', {
    staticStyle: {
      "text-align": "right",
      "padding-bottom": "30px"
    }
  }, [_c('el-pagination', {
    attrs: {
      "layout": "total ,sizes , prev, pager, next",
      "total": _vm.loanActionList.totalCount,
      "current-page": _vm.currentPage,
      "page-sizes": [10, 20, 50]
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  })], 1)], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('i', {
    staticClass: "el-icon-back"
  }), _vm._v("Back")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kline-section-interact"
  }, [_c('div', {
    staticClass: "kline-section-title"
  }, [_vm._v("History")])]);
}];
export { render, staticRenderFns };