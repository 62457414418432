var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.name))]), _c('div', {
    staticClass: "sub-menu"
  }, [_c('el-menu', {
    staticClass: "el-menu-demo",
    attrs: {
      "default-active": _vm.activeIndex,
      "mode": "horizontal"
    },
    on: {
      "select": _vm.handleSelect
    }
  }, _vm._l(_vm.menus, function (m) {
    return _c('el-menu-item', {
      key: m.index,
      attrs: {
        "index": m.index
      }
    }, [_vm._v(_vm._s(m.name))]);
  }), 1)], 1), _vm.showSub ? _c('div', [_c('router-view', {
    attrs: {
      "name": "sub"
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };