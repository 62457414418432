import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "address-display"
  }, [_c('el-tooltip', {
    attrs: {
      "effect": "light",
      "content": _vm.addressLabel.address,
      "placement": "top-start"
    }
  }, [_c('div', {
    staticClass: "address-shorter"
  }, [_vm._v(" " + _vm._s(_vm.addressLabel.address) + " ")])]), _c('div', [_c('img', {
    staticClass: "copyImg",
    attrs: {
      "src": _vm.localImg
    },
    on: {
      "click": function ($event) {
        return _vm.copyAddress(_vm.addressLabel.originalAddress);
      },
      "mouseenter": function ($event) {
        return _vm.mouseenter();
      },
      "mouseleave": function ($event) {
        return _vm.mouseleave();
      }
    }
  })]), _vm.addressLabel ? _c('el-tooltip', {
    attrs: {
      "disabled": _vm.addressLabel.description == '',
      "effect": "light",
      "content": _vm.addressLabel.description,
      "placement": "top-start"
    }
  }, [_c('div', {
    staticClass: "address-label"
  }, [_vm._v(" " + _vm._s(_vm.addressLabel.addressTag) + " ")])]) : _vm._e(), _c('textarea', {
    staticClass: "textarea-hidden",
    attrs: {
      "id": 'input-' + _vm.addressLabel.originalAddress
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };