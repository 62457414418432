var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "component-wrap"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "info"
  }, _vm._l(_vm.numData, function (v, i) {
    return _c('div', {
      key: i,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "num"
    }, [v.value !== undefined ? _c('countTo', {
      attrs: {
        "startVal": 0,
        "endVal": v.value,
        "duration": 3000
      }
    }) : _c('span', [_vm._v("--")])], 1), _c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(v.display)), _c('el-tooltip', {
      attrs: {
        "placement": "top",
        "trigger": "hover"
      }
    }, [_c('div', {
      staticClass: "tooltip-300px",
      attrs: {
        "slot": "content"
      },
      slot: "content"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(v.comment)
      }
    })]), _c('i', {
      staticClass: "el-icon-warning-outline"
    })])], 1)]);
  }), 0)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "unique-address",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/89ceab6e-9028-4fb5-9ec8-60ad9a9dcf6a?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "500",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "active-address",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/4ba55dfa-f461-4a5d-beb7-6d5654fd2161?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "500",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-layers",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/4b884dee-285c-482b-94e8-6297b3c02a16",
      "frameborder": "0",
      "width": "100%",
      "height": "500",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-category",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/ac6d3130-dea4-4990-9eb0-89a52d2ee44f",
      "frameborder": "0",
      "width": "100%",
      "height": "650",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-daily-trend",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/185cff5d-9baa-4056-94d7-e0cb6d7c4ee2?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "600",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-daily-stack",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/4d703600-a8ff-4217-a21d-beef5918c649?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "700",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "gross-inflation-breakout",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/4ce00ac3-17a3-4e8e-b749-58ad2bf59d3a?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "700",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "dapp-activity",
    attrs: {
      "src": "https://analytix.web3go.xyz/public/dashboard/c99f7e10-de9c-4d9f-992b-279b6e0ae2e2",
      "frameborder": "0",
      "width": "100%",
      "height": "700",
      "allowtransparency": ""
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };