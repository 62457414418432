import { render, staticRenderFns } from "./WalletLabelSetting.vue?vue&type=template&id=3569a5f8&"
import script from "./WalletLabelSetting.vue?vue&type=script&lang=js&"
export * from "./WalletLabelSetting.vue?vue&type=script&lang=js&"
import style0 from "./WalletLabelSetting.vue?vue&type=style&index=0&id=3569a5f8&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports