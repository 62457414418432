import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nft g-wrap"
  }, [_c('div', {
    staticClass: "nftHead"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/');
      }
    }
  }), _c('img', {
    staticClass: "nftHead-logo",
    attrs: {
      "src": require("../../assets/images/nft/logo.png"),
      "alt": ""
    }
  }), _vm._m(0)]), _c('div', {
    staticClass: "nftNav"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTProfiler');
      }
    }
  }, [_vm._v("Market Overview")]), _c('span', {
    staticClass: "act"
  }, [_vm._v("Mint Master")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTGraph');
      }
    }
  }, [_vm._v("NFT Graph")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTTimeMachine');
      }
    }
  }, [_vm._v("Time Machine")])]), _c('div', {
    staticClass: "nftNavCon"
  }, [_c('div', {
    staticClass: "nftNavConList"
  }, [_c('div', {
    staticClass: "nftNavConList-head"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Latest Mints")]), _c('el-tooltip', {
    attrs: {
      "effect": "dark",
      "content": "Latest NFT project sorted by mint count  in 7 days",
      "placement": "top-start"
    }
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/images/nft/info.png"),
      "alt": ""
    }
  })])], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.nftNavConListLoading,
      expression: "nftNavConListLoading"
    }],
    staticClass: "nftNavConList-table",
    attrs: {
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading"
    }
  }, [_c('div', {
    staticClass: "nftNavConList-table-header"
  }, [_c('el-row', _vm._l(_vm.tableTitle, function (item, index) {
    return _c('el-col', {
      key: item.title,
      class: item.className,
      attrs: {
        "span": item.col,
        "align": item.align
      }
    }, [_vm._v(_vm._s(item.title) + " "), _c('div', {
      staticClass: "sort"
    }, [_c('p', [_c('span', {
      staticClass: "up",
      class: item.sort === 'ASC' ? 'act' : '',
      on: {
        "click": function ($event) {
          return _vm.getTopTradedCollectionsSort(item.value, 'ASC', index);
        }
      }
    }), _c('span', {
      staticClass: "down",
      class: item.sort === 'DESC' ? 'act' : '',
      on: {
        "click": function ($event) {
          return _vm.getTopTradedCollectionsSort(item.value, 'DESC', index);
        }
      }
    })])])]);
  }), 1)], 1), _c('div', {
    staticClass: "nftNavConList-table-content"
  }, [_c('div', {
    staticClass: "lp-row"
  }, _vm._l(_vm.renderListData, function (item) {
    return _c('el-row', {
      key: item.id
    }, [_c('el-col', {
      staticClass: "collection hover-item",
      attrs: {
        "span": 8,
        "align": "left"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c('div', [_c('el-tooltip', {
      attrs: {
        "effect": "dark",
        "content": item.collection_name,
        "placement": "top-start"
      }
    }, [_c('span', {
      staticClass: "collection_name"
    }, [_vm._v(_vm._s(item.collection_name))])])], 1)]), _c('el-col', {
      attrs: {
        "span": 8
      }
    }, [_c('div', {
      staticClass: "mint_count"
    }, [_vm._v(" " + _vm._s(item.mint_count)), _c('el-progress', {
      staticClass: "progress",
      attrs: {
        "show-text": false,
        "percentage": +(item.mint_count / _vm.listTotal * 100).toFixed(2)
      }
    })], 1)]), _c('el-col', {
      attrs: {
        "span": 8
      }
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.filterTimestamp_created_at(item.mint_first_timestamp)) + " ")])])], 1);
  }), 1)]), _c('div', {
    staticClass: "nftNavConList-table-pagination"
  }, [_c('el-pagination', {
    attrs: {
      "hide-on-single-page": true,
      "total": _vm.totalCount,
      "layout": "prev, pager, next",
      "current-page": _vm.query.pageIndex
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "current-change": _vm.handleCurrentChange
    }
  })], 1)])]), _c('div', {
    staticClass: "nftNavConLine"
  }, [_c('div', {
    staticClass: "nftNavConLine-head"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Minting Volume 7D")]), _c('el-tooltip', {
    attrs: {
      "effect": "dark",
      "placement": "top-start"
    }
  }, [_c('div', {
    staticClass: "tooltip-300px",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(" Minting volume for projects that started minting in the last 7 days, no gas fee included. ")]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/images/nft/info.png"),
      "alt": ""
    }
  })])], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.nftNavConListLoading,
      expression: "nftNavConListLoading"
    }],
    attrs: {
      "id": "nftNavConLine",
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading"
    }
  }), _c('ul', {
    staticClass: "mintionList"
  }, _vm._l(_vm.mintionList, function (item) {
    return _c('li', {
      key: item.title
    }, [_c('span', [_vm._v(_vm._s(item.point) + "%")])]);
  }), 0), _c('div', {
    staticClass: "total"
  }, [_c('span', {
    staticClass: "mintVolumeTotal"
  }, [_vm._v(_vm._s(_vm.mintVolumeTotal))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("Total")])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nftHead-con"
  }, [_c('h2', {
    staticClass: "nftHead-con-title"
  }, [_vm._v("NFT Garden")]), _c('p', {
    staticClass: "nftHead-con-des"
  }, [_vm._v("By Web3go Official Team")])]);
}];
export { render, staticRenderFns };