var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "profile-index-page"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "info-left"
  }, [_c('identity-icon-plus', {
    attrs: {
      "addressInfo": _vm.polkadotAddressInfo
    }
  })], 1), _c('div', {
    staticClass: "split"
  }), _c('div', {
    staticClass: "item"
  }, [_vm._m(0), _c('div', {
    staticClass: "text-wrap hover-item",
    on: {
      "click": _vm.openDrawer
    }
  }, [_c('span', [_vm._v("All available address")]), _c('img', {
    attrs: {
      "src": require("@/assets/images/profile/arrow.png"),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "split"
  }), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v("$ " + _vm._s(_vm._f("format2")(_vm.totalAmount)))]), _c('img', {
    staticStyle: {
      "margin-right": "15px"
    },
    attrs: {
      "src": require("@/assets/images/profile/info2.png"),
      "alt": ""
    }
  })]), _vm._m(1)])]), _c('div', {
    staticClass: "nftNav-wrap"
  }, [_c('div', {
    staticClass: "nftNav"
  }, _vm._l(_vm.navList, function (v) {
    return _c('span', {
      key: v.name,
      staticClass: "hover-item",
      class: {
        active: _vm.currentNav.name == v.name
      },
      on: {
        "click": function ($event) {
          return _vm.goToNav(v);
        }
      }
    }, [_vm._v(_vm._s(v.name))]);
  }), 0), _vm.currentNav.name == 'Balance' ? _c('div', {
    staticClass: "checkbox"
  }, [_c('el-checkbox', {
    model: {
      value: _vm.showZeroBalance,
      callback: function ($$v) {
        _vm.showZeroBalance = $$v;
      },
      expression: "showZeroBalance"
    }
  }, [_vm._v("Show zero balances")])], 1) : _vm._e()]), _c('div', {
    staticClass: "component-wrap"
  }, [_c(_vm.currentNav.component, {
    ref: "component",
    tag: "component",
    attrs: {
      "addressList": _vm.addressList,
      "balanceNavData": _vm.balanceNavData,
      "showZeroBalance": _vm.showZeroBalance
    }
  })], 1), _c('el-drawer', {
    attrs: {
      "size": "480",
      "visible": _vm.visible,
      "with-header": false,
      "direction": "rtl"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "drawer-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v("Address list")]), _c('img', {
    staticClass: "hover-item",
    attrs: {
      "src": require("@/assets/images/profile/close.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  })]), _c('div', {
    staticClass: "address-list"
  }, _vm._l(_vm.addressList, function (v, i) {
    return _c('div', {
      key: i,
      staticClass: "item"
    }, [_c('img', {
      staticClass: "left",
      attrs: {
        "src": _vm.getIcon(v),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "middle"
    }, [_c('div', {
      staticClass: "item-title"
    }, [_vm._v(_vm._s(v.network || "Public Key"))]), _c('div', {
      staticClass: "item-text"
    }, [_vm._v(" " + _vm._s(v.value) + " ")])]), _c('div', {
      staticClass: "copy-wrap"
    }, [_c('img', {
      staticClass: "copy",
      attrs: {
        "title": "copy",
        "src": require("@/assets/images/profile/copy.png"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.copy(v.value);
        }
      }
    })])]);
  }), 0)])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" Address list ")]), _c('img', {
    attrs: {
      "src": require("@/assets/images/profile/info1.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text-wrap"
  }, [_c('span', [_vm._v("The total amount of account")])]);
}];
export { render, staticRenderFns };