import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content custom-query-page"
  }, [_c('div', {
    staticClass: "common-back-title"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.editSupport ? "Create New Analysis" : _vm.publishData.title))]), _c('el-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editSupport,
      expression: "editSupport"
    }],
    staticClass: "btn-publish",
    staticStyle: {
      "float": "right",
      "margin-top": "15px",
      "margin-right": "30px"
    },
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.publish
    }
  }, [_vm._v("Publish")])], 1), _c('div', {
    staticClass: "main g-wrap"
  }, [_vm.editSupport ? _c('div', {
    staticClass: "div-edit"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "select-row"
  }, [_c('div', {
    staticClass: "select-description"
  }, [_vm._v("Find tokens")]), _c('el-select', {
    staticClass: "select-token",
    attrs: {
      "clearable": "",
      "placeholder": "tokens"
    },
    model: {
      value: _vm.query.selectedToken,
      callback: function ($$v) {
        _vm.$set(_vm.query, "selectedToken", $$v);
      },
      expression: "query.selectedToken"
    }
  }, _vm._l(_vm.chainList4Select, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1), _c('div', {
    staticClass: "select-description"
  }, [_vm._v("shown with")]), _c('el-select', {
    staticClass: "select-showfields",
    attrs: {
      "multiple": "",
      "collapse-tags": "",
      "placeholder": "fields"
    },
    model: {
      value: _vm.query.selectedFields,
      callback: function ($$v) {
        _vm.$set(_vm.query, "selectedFields", $$v);
      },
      expression: "query.selectedFields"
    }
  }, _vm._l(_vm.fields4Select, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1), _c('div', {
    staticClass: "select-description"
  }, [_vm._v("sorted by")]), _c('div', [_c('el-select', {
    staticClass: "select-orderby",
    attrs: {
      "clearable": "",
      "placeholder": "fields"
    },
    model: {
      value: _vm.query.orderByFields,
      callback: function ($$v) {
        _vm.$set(_vm.query, "orderByFields", $$v);
      },
      expression: "query.orderByFields"
    }
  }, _vm._l(_vm.fields4Orderby, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "select-description"
  }, [_vm._v("takes")]), _c('div', [_c('el-input', {
    staticClass: "input-recordCount",
    attrs: {
      "placeholder": "some"
    },
    model: {
      value: _vm.query.recordCount,
      callback: function ($$v) {
        _vm.$set(_vm.query, "recordCount", $$v);
      },
      expression: "query.recordCount"
    }
  })], 1), _c('div', {
    staticClass: "select-description"
  }, [_vm._v("records.")]), _c('div', {
    staticClass: "buttons"
  }, [_c('el-button', {
    staticClass: "btn-run",
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.runQuery
    }
  }, [_vm._v("Run")]), _c('el-button', {
    staticClass: "btn-advanced-query",
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.showAdvancedQuery
    }
  }, [_vm._v("Ad. Query")])], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.advancedQueryVisible,
      expression: "advancedQueryVisible"
    }],
    staticClass: "div-advanced-query"
  }, [_c('div', {
    staticClass: "div-advanced-query-schema"
  }, [_c('div', {
    staticClass: "div-advanced-query-schema-select"
  }, [_c('el-select', {
    attrs: {
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.refreshSchema();
      }
    },
    model: {
      value: _vm.schemaOption.schemaType,
      callback: function ($$v) {
        _vm.$set(_vm.schemaOption, "schemaType", $$v);
      },
      expression: "schemaOption.schemaType"
    }
  }, _vm._l(_vm.schemaTypeOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1), _c('div', {
    staticClass: "tree"
  }, [_c('div', {
    staticClass: "table-collapse"
  }, [_c('el-collapse', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.dataTables, function (t) {
    return _c('el-collapse-item', {
      key: t.tableName,
      attrs: {
        "name": t.tableName
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('div', {
      staticClass: "tableName"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/dropdown-icon.png"),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "text"
    }, [_vm._v(_vm._s(t.tableName))])])]), _c('div', {
      staticClass: "tableComment"
    }, [_vm._v(" " + _vm._s(t.tableComment) + " ")]), _vm._l(t.columns, function (tc) {
      return _c('div', {
        key: tc.columnName
      }, [_c('div', {
        staticClass: "column-display"
      }, [_c('i', {
        staticClass: "el-icon-document"
      }), _c('div', {
        staticClass: "columnName"
      }, [_vm._v(_vm._s(tc.columnName))]), _c('div', {
        staticClass: "dataType"
      }, [_vm._v("(" + _vm._s(tc.dataType) + ")")])]), _c('div', {
        staticClass: "columnComment"
      }, [_vm._v(_vm._s(tc.columnComment))])]);
    })], 2);
  }), 1)], 1)])], 1)]), _c('div', {
    staticClass: "div-advanced-query-editor"
  }, [_c('el-button', {
    staticClass: "btn-run",
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.runQuery
    }
  }, [_vm._v("Run")]), _c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "placeholder": "eg: SELECT count(1) from wallet_address_info "
    },
    model: {
      value: _vm.query.queryExpression,
      callback: function ($$v) {
        _vm.$set(_vm.query, "queryExpression", $$v);
      },
      expression: "query.queryExpression"
    }
  })], 1)])]) : _vm._e(), _c('div', {
    staticClass: "query-result"
  }, [_c('el-tabs', {
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.activeTabName,
      callback: function ($$v) {
        _vm.activeTabName = $$v;
      },
      expression: "activeTabName"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('el-tab-pane', {
      directives: [{
        name: "loading",
        rawName: "v-loading",
        value: _vm.loading,
        expression: "loading"
      }],
      key: tab.name,
      attrs: {
        "label": tab.label,
        "name": tab.name
      }
    }, [_c('keep-alive', [_c(tab.component, {
      ref: "vizComponents",
      refInFor: true,
      tag: "component",
      attrs: {
        "data": _vm.resultData,
        "options": tab.options,
        "id": tab.name
      },
      on: {
        "viz-component-add": _vm.vizComponentAdd,
        "viz-component-remove": _vm.vizComponentRemove,
        "viz-component-update-options": _vm.vizComponentUpdateOptions
      }
    })], 1), tab.label == 'Chart' ? _c('div', {
      staticClass: "tag-wrap"
    }, _vm._l(_vm.tags, function (v) {
      return _c('div', {
        key: v.name,
        staticClass: "tag",
        class: {
          active: _vm.activeTagName == v.name
        },
        on: {
          "click": function ($event) {
            _vm.activeTagName = v.name;
          }
        }
      }, [_c('span', [_vm._v(_vm._s(v.label))]), _vm.editSupport ? _c('i', {
        staticClass: "el-icon-close",
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.vizComponentRemove(v.name);
          }
        }
      }) : _vm._e()]);
    }), 0) : _vm._e(), tab.label == 'Chart' ? _vm._l(_vm.tags, function (v) {
      return _c('div', {
        key: v.name
      }, [_c('keep-alive', [_c(v.component, {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: v.name == _vm.activeTagName,
          expression: "v.name == activeTagName"
        }],
        ref: "tagVizComponents",
        refInFor: true,
        tag: "component",
        attrs: {
          "data": _vm.resultData,
          "options": v.options,
          "id": v.name
        },
        on: {
          "viz-component-add": _vm.vizComponentAdd,
          "viz-component-remove": _vm.vizComponentRemove,
          "viz-component-update-options": _vm.vizComponentUpdateOptions
        }
      })], 1)], 1);
    }) : _vm._e()], 2);
  }), 1)], 1)]), _c('el-dialog', {
    staticClass: "custom-query-dialog",
    attrs: {
      "title": "",
      "width": "520px",
      "visible": _vm.publishDialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.publishDialogVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "dialog-title"
  }, [_vm._v("Publish Query")]), _c('el-form', [_c('el-form-item', {
    attrs: {
      "label": "Title"
    }
  }, [_c('el-input', {
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.publishData.title,
      callback: function ($$v) {
        _vm.$set(_vm.publishData, "title", $$v);
      },
      expression: "publishData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Description"
    }
  }, [_c('el-input', {
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.publishData.description,
      callback: function ($$v) {
        _vm.$set(_vm.publishData, "description", $$v);
      },
      expression: "publishData.description"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Created"
    }
  }, [_c('el-input', {
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.publishData.created,
      callback: function ($$v) {
        _vm.$set(_vm.publishData, "created", $$v);
      },
      expression: "publishData.created"
    }
  })], 1)], 1), _c('div', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.publishDialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.doPublish
    }
  }, [_vm._v("Confirm")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };