var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ProfileNFT ProfileNFT-rmrk"
  }, [_c('div', {
    staticClass: "common-profile-title"
  }, [_c('img', {
    attrs: {
      "src": "/static/parachain-icon/kusama.png",
      "alt": ""
    }
  }), _c('span', [_vm._v("KSM " + _vm._s(_vm.totalamount))])]), _c('div', {
    staticClass: "nftNavCon"
  }, [_c('div', {
    staticClass: "nftNavConList"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "nftNavConList-table",
    attrs: {
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading"
    }
  }, [_c('div', {
    staticClass: "nftNavConList-table-header"
  }, [_c('el-row', _vm._l(_vm.tableTitle, function (item) {
    return _c('el-col', {
      key: item.value,
      class: item.className,
      attrs: {
        "span": item.col,
        "align": item.align,
        "offset": item.offset
      }
    }, [_vm._v(_vm._s(item.title) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "nftNavConList-table-content"
  }, [_c('div', {
    staticClass: "lp-row"
  }, _vm._l(_vm.renderListData, function (item) {
    return _c('el-row', {
      key: item.id
    }, _vm._l(_vm.tableTitle, function (cell, index) {
      return _c('el-col', {
        key: item[cell.value],
        class: _vm.tableTitle[index].className,
        attrs: {
          "span": _vm.tableTitle[index].col,
          "align": _vm.tableTitle[index].align,
          "offset": _vm.tableTitle[index].offset
        },
        nativeOn: {
          "click": function ($event) {
            _vm.tableTitle[index].click && _vm.tableTitle[index].click(item);
          }
        }
      }, [_vm.tableTitle[index].img ? _c('img', {
        attrs: {
          "src": _vm.tableTitle[index].img(item),
          "alt": ""
        }
      }) : _vm._e(), _vm._v(" " + _vm._s(_vm.tableTitle[index].filter ? _vm.tableTitle[index].filter(item[cell.value]) : item[cell.value]) + " ")]);
    }), 1);
  }), 1)]), !_vm.listData.length ? _c('div', {
    staticClass: "common-profile-table-nodata"
  }, [_vm._v(" No Data ")]) : _vm._e(), _c('div', {
    staticClass: "nftNavConList-table-pagination"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "current-page": _vm.query.pageIndex,
      "page-size": _vm.query.pageSize,
      "page-sizes": [10, 20, 50, 100],
      "total": _vm.totalCount
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:pageSize": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "update:page-size": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };