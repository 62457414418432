var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content cdp-page"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ifWhiteTheme,
      expression: "ifWhiteTheme"
    }],
    staticClass: "common-back-title",
    staticStyle: {
      "margin-left": "-100px",
      "margin-right": "-100px"
    }
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("Karura")])]), _c('div', {
    staticClass: "search-filter"
  }, [_c('div', {
    staticClass: "chainStatistic"
  }, [_c('div', [_c('div', {
    staticClass: "statistic-item clearfix"
  }, [_c('div', {
    staticClass: "icon icon-total"
  }), _c('div', {
    staticClass: "value"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "value-formated"
  }, [_vm._v(" " + _vm._s(_vm.statisticData.collateralFormat)), _c('span', {
    staticClass: "value-formated-unit"
  }, [_vm._v("CDPS")])])])]), _c('div', {
    staticClass: "statistic-item clearfix"
  }, [_c('div', {
    staticClass: "icon icon-debt"
  }), _c('div', {
    staticClass: "value"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Debt")]), _c('div', {
    staticClass: "value-formated"
  }, [_vm._v(" " + _vm._s(_vm.statisticData.debitFormat) + " "), _c('span', {
    staticClass: "value-formated-unit"
  }, [_vm._v("KUSD")])])])])])]), _c('div', [_c('el-input', {
    staticClass: "cdp-search-filter-input",
    attrs: {
      "clearable": "",
      "prefix-icon": "el-icon-search",
      "placeholder": "Enter User Address",
      "type": "text"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchLoanPositionByAccountId.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.accountId,
      callback: function ($$v) {
        _vm.$set(_vm.query, "accountId", $$v);
      },
      expression: "query.accountId"
    }
  })], 1)]), _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "filter"
  }, [_c('el-select', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "multiple": "",
      "collapse-tags": "",
      "placeholder": "filter status"
    },
    on: {
      "change": _vm.loadLoanPositionTable
    },
    model: {
      value: _vm.query.filterStatus,
      callback: function ($$v) {
        _vm.$set(_vm.query, "filterStatus", $$v);
      },
      expression: "query.filterStatus"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1), _c('el-checkbox', {
    on: {
      "change": _vm.loadLoanPositionTable
    },
    model: {
      value: _vm.query.hideLoanCompleted,
      callback: function ($$v) {
        _vm.$set(_vm.query, "hideLoanCompleted", $$v);
      },
      expression: "query.hideLoanCompleted"
    }
  }, [_vm._v("Hide Loan Completed")])], 1), _c('div', {
    staticClass: "cdp-list"
  }, [_c('div', {
    staticClass: "cdp-list-header"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 10,
      "align": "left"
    }
  }, [_vm._v("ID")]), _c('el-col', {
    attrs: {
      "span": 4
    }
  }, [_vm._v("Debt")]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_vm._v("Collateral")]), _c('el-col', {
    attrs: {
      "span": 3
    }
  }, [_vm._v("Ratio")]), _c('el-col', {
    attrs: {
      "span": 2
    }
  }, [_vm._v("Status")])], 1)], 1), _c('div', {
    staticClass: "cdp-list-content"
  }, _vm._l(_vm.loanPositionTableData, function (d) {
    return _c('div', {
      key: d.accountId,
      staticClass: "lp-row"
    }, [_c('el-row', [_c('el-col', {
      attrs: {
        "span": 10,
        "align": "left"
      }
    }, [_c('div', {
      staticClass: "accountId"
    }, [_c('img', {
      staticClass: "account-logo",
      attrs: {
        "src": require("./../../assets/images/kusama-logo.png")
      }
    }), _c('address-display', {
      attrs: {
        "address": d.accountId
      }
    })], 1)]), _c('el-col', {
      attrs: {
        "span": 4
      }
    }, [_c('div', {
      staticClass: "debt"
    }, [_vm._v(_vm._s(d.debitFormat) + " KUSD")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('div', {
      staticClass: "collateral"
    }, [_vm._v(" " + _vm._s(d.collateralFormat) + " KSM ")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('div', {
      staticClass: "ratio",
      class: _vm.getShowColor(d)
    }, [_vm._v(" " + _vm._s(d.ratioPercentage) + " % ")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('div', {
      staticClass: "status",
      class: _vm.getShowColor(d)
    }, [_vm._v(" " + _vm._s(d.status) + " ")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('div', {
      staticClass: "view",
      on: {
        "click": function ($event) {
          return _vm.showLoanPositionDetail(d);
        }
      }
    }, [_vm._v(" view  "), _c('i', {
      staticClass: "el-icon-right"
    })])])], 1)], 1);
  }), 0), _c('div', {
    staticClass: "cdp-list-pagination"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next,sizes,jumper",
      "current-page": _vm.query.pageIndex,
      "page-size": _vm.query.pageSize,
      "page-sizes": [10, 20, 50, 100],
      "total": _vm.totalCount
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:pageSize": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "update:page-size": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };