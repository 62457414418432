var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "user-info"
  }, [_c('div', {
    staticClass: "big-left"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("UserProfile")]), _c('div', {
    staticClass: "left-nav"
  }, [_c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.tabIndex == 1
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 1;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/login/info.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("User info")]), _c('div', {
    staticClass: "right-line"
  })]), _c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.tabIndex == 2
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 2;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/login/favority.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Favorites")]), _c('div', {
    staticClass: "right-line"
  })])])]), _c('div', {
    staticClass: "big-right"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabIndex == 1,
      expression: "tabIndex == 1"
    }],
    staticClass: "tab-content info"
  }, [_c('div', {
    staticClass: "inner"
  }, [_c('div', {
    staticClass: "info-top"
  }, [_c('div', {
    staticClass: "avatar-wrap"
  }, [_c('userAvatar', {
    ref: "userAvatar"
  }), _c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.form.name))])], 1)]), _c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "label-position": "top",
      "model": _vm.form,
      "label-width": "80px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Email"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    staticClass: "btn",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save Changes")])], 1)], 1)], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabIndex == 2,
      expression: "tabIndex == 2"
    }],
    staticClass: "tab-content favourity"
  }, [_c('el-row', _vm._l(_vm.favorityList, function (v) {
    return _c('el-col', {
      key: v.id,
      staticClass: "col",
      attrs: {
        "span": 6
      }
    }, [_c('div', {
      staticClass: "item"
    }, [_c('img', {
      staticClass: "icon",
      attrs: {
        "src": v.icon,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "text-wrap",
      on: {
        "click": function ($event) {
          return _vm.showCardDetail(v.linkUrl);
        }
      }
    }, [_c('span', {
      attrs: {
        "title": v.link
      }
    }, [_vm._v(_vm._s(_vm.splitWord(v.link)))])]), _c('div', {
      staticClass: "del-icon-wrap"
    }, [_c('img', {
      staticClass: "del-icon",
      attrs: {
        "src": require("@/assets/images/login/del.png"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.remove(v);
        }
      }
    })])])]);
  }), 1)], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };