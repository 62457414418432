var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "viz-config viz-config-page"
  }, [_vm.$route.query.editSupport === true || _vm.$route.query.editSupport === 'true' ? _c('div', {
    staticClass: "viz-config-select"
  }, [_c('el-select', {
    attrs: {
      "placeholder": "choose visualization type",
      "clearable": ""
    },
    model: {
      value: _vm.choosedVizType,
      callback: function ($$v) {
        _vm.choosedVizType = $$v;
      },
      expression: "choosedVizType"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1), _c('el-button', {
    staticClass: "btn-add",
    on: {
      "click": _vm.add
    }
  }, [_vm._v("Add")])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };