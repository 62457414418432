import "core-js/modules/es.regexp.exec.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "query"
  }, [_c('el-input', {
    model: {
      value: _vm.collection_id,
      callback: function ($$v) {
        _vm.collection_id = $$v;
      },
      expression: "collection_id"
    }
  }), _c('div', [_c('el-button', {
    on: {
      "click": _vm.search
    }
  }, [_vm._v("Search")])], 1)], 1), _c('div', {
    staticClass: "result"
  }, [_vm._v(" " + _vm._s(_vm.collection_item) + " ")]), _c('div', {
    staticClass: "update"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("collection_id:")]), _c('el-input', {
    staticStyle: {
      "width": "400px"
    },
    model: {
      value: _vm.collection_id,
      callback: function ($$v) {
        _vm.collection_id = $$v;
      },
      expression: "collection_id"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("collection_name:")]), _c('el-input', {
    staticStyle: {
      "width": "400px"
    },
    model: {
      value: _vm.collection_name,
      callback: function ($$v) {
        _vm.collection_name = $$v;
      },
      expression: "collection_name"
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.updateCollectionName
    }
  }, [_vm._v("Update Name")])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };