var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "component-wrap"
  }, [_c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/35331a65-524e-48f7-9c8a-32d448342ff4",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })]), _c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/70aaec26-ed58-400b-8cbf-bc7f68982ff3",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })]), _c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/379c21c5-55d8-49c0-8d10-cc02482536b8",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })]), _c('div', {
    staticClass: "chart"
  }, [_c('iframe', {
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/34bcef85-3b49-4f81-aede-5730eccc9e68",
      "frameborder": "0",
      "width": "100%",
      "height": "588",
      "allowtransparency": ""
    }
  })])]);
}];
export { render, staticRenderFns };