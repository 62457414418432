var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content contributionDetail"
  }, [_c('div', {
    staticClass: "common-back-title",
    staticStyle: {
      "padding": "0"
    }
  }, [_c('div', {
    staticClass: "g-wrap"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.info.paraId) + " " + _vm._s(_vm.query.projectName))]), _c('div', {
    staticClass: "share-wrap-top"
  }, [_c('img', {
    attrs: {
      "title": "Share to Twitter",
      "src": require("@/assets/images/twitter@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShareAll('twitter', '#share-all', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  }), _c('img', {
    attrs: {
      "title": "Capture snapshot",
      "src": require("@/assets/images/download@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShareAll('download', '#share-all', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  })])])]), _c('div', {
    staticClass: "head extend-2-side-removed"
  }, [_c('div', {
    staticClass: "g-wrap"
  }, [_c('div', {
    staticClass: "head-mian",
    attrs: {
      "id": "share-all"
    }
  }, [_c('div', {
    staticClass: "head-mian-con"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('img', {
    staticClass: "color",
    attrs: {
      "src": _vm.query.iconPath
    }
  }), _c('span', {
    staticClass: "test"
  }, [_vm._v(_vm._s(_vm.query.projectName))])]), _c('div', {
    staticClass: "tag"
  }, [_c('span', {
    staticClass: "tag-item"
  }, [_vm._v(" " + _vm._s(_vm.info.paraId) + " ")]), _c('span', {
    staticClass: "tag-item"
  }, [_vm._v(" " + _vm._s(_vm.info.status) + " ")])])]), _c('div', {
    staticClass: "head-mian-item"
  }, [_vm._m(0), _c('el-tooltip', {
    attrs: {
      "content": _vm.formatedTokenValue(_vm.info.totalRaisedValue),
      "placement": "bottom"
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.formatedCap(_vm.info.totalRaisedValue)))])])], 1), _c('div', {
    staticClass: "head-mian-item"
  }, [_vm._m(1), _c('h2', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.totalContributionCount, 0)))])]), _c('div', {
    staticClass: "head-mian-item"
  }, [_vm._m(2), _c('h2', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.totalContributorCount, 0)))])]), _c('div', {
    staticClass: "head-mian-item"
  }, [_vm._m(3), _c('el-tooltip', {
    attrs: {
      "content": _vm.formatedTokenValue(_vm.info.totalCapitalValue),
      "placement": "bottom"
    }
  }, [_c('h2', [_vm._v(_vm._s(_vm.formatedCap(_vm.info.totalCapitalValue)))])])], 1), _c('div', {
    staticClass: "head-mian-item"
  }, [_vm._m(4), _c('h2', [_vm._v(_vm._s(_vm.info.progress) + "%")])])])])]), _c('div', {
    staticClass: "g-wrap"
  }, [_vm._l(_vm.adList, function (ad) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showAd(ad.name),
        expression: "showAd(ad.name)"
      }],
      key: ad.name,
      staticClass: "ad-link"
    }, [_c('div', {
      class: ad.class
    }, [_c('img', {
      attrs: {
        "src": ad.image
      },
      on: {
        "click": function ($event) {
          return _vm.gotoLink(ad);
        }
      }
    })])]);
  }), _c('div', {
    staticClass: "header-filter"
  }, [_c('div', {
    staticClass: "filter-left"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.totalContributionCount) + " Contributions")])]), _c('div', {
    staticClass: "filter-right"
  }, [_c('div', {
    staticClass: "contribution-account"
  }, [_c('el-input', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "placeholder": "filter contributor account"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.refreshData(true);
      }
    },
    model: {
      value: _vm.query.filterContributionAccount,
      callback: function ($$v) {
        _vm.$set(_vm.query, "filterContributionAccount", $$v);
      },
      expression: "query.filterContributionAccount"
    }
  })], 1), _c('div', {
    staticClass: "contribution-order"
  }, [_c('el-select', {
    attrs: {
      "placeholder": "please choose sort order"
    },
    on: {
      "change": function ($event) {
        return _vm.refreshData(true);
      }
    },
    model: {
      value: _vm.query.filterOrderOption,
      callback: function ($$v) {
        _vm.$set(_vm.query, "filterOrderOption", $$v);
      },
      expression: "query.filterOrderOption"
    }
  }, _vm._l(_vm.orderOptions, function (option) {
    return _c('el-option', {
      key: option.value,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "contribution-totalAmount"
  }, [_vm._v(" Total: " + _vm._s(_vm._f("formatDOT")(_vm.contributionAmount)) + "  DOT ")]), _c('div', {
    staticClass: "contribution-contributorStatisticDisplay",
    domProps: {
      "innerHTML": _vm._s(_vm.getContributorStatisticDisplayText())
    }
  }), _c('div', {
    staticClass: "reset"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.reset(true);
      }
    }
  }, [_vm._v("Reset")])], 1)])])], 2), _c('div', {
    staticClass: "g-wrap"
  }, [_c('div', {
    staticClass: "data-table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    key: Math.random(),
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading",
      "data": _vm.contributionTableData
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Contributor",
      "prop": "account",
      "width": "450"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('address-display', {
          attrs: {
            "address": scope.row.account
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Amount",
      "prop": "amount",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.amountFormated) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "ratio",
      "label": "Ratio",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.ratio) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "createdAt",
      "label": "Contributed at"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', [_vm._v("Block: " + _vm._s(scope.row.blockNum))]), _c('div', [_vm._v(_vm._s(_vm._f("formatDate")(_vm.filterTime(scope.row.createdAt))))])];
      }
    }])
  })], 1), _c('el-pagination', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.contributionCount4Page > 0,
      expression: "contributionCount4Page > 0"
    }],
    attrs: {
      "id": "g-pagination",
      "background": "",
      "current-page": _vm.query.currentPage,
      "total": _vm.contributionCount4Page,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.query.pageSize,
      "layout": "prev, pager, next, sizes, jumper"
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.query, "currentPage", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.query, "currentPage", $event);
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1), _c('div', {
    staticClass: "chart"
  }, [_c('h2', {
    staticClass: "title",
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v(" Top 5 contributors by number of contributions "), _c('div', {
    staticClass: "share-wrap"
  }, [_c('img', {
    attrs: {
      "title": "Share to Twitter",
      "src": require("@/assets/images/twitter@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('twitter', '#share1', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  }), _c('img', {
    attrs: {
      "title": "Capture snapshot",
      "src": require("@/assets/images/download@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('download', '#share1', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  })])]), _c('div', {
    attrs: {
      "id": "share1"
    }
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    ref: "transactionChart",
    staticClass: "transactionChart"
  })]), _c('div', {
    staticClass: "right"
  }, [_c('ul', _vm._l(_vm.getContributorsRankingListOfnumberOfContributions, function (i, index) {
    return _c('li', {
      key: i.account
    }, [_c('div', [index < 3 ? _c('span', {
      staticClass: "rank"
    }, [_c('img', {
      attrs: {
        "src": require(`@/assets/images/crowdloan/kusama_detail_top${index + 1}.png`),
        "alt": ""
      }
    })]) : _c('span', {
      staticClass: "rank"
    }, [_vm._v(_vm._s(index + 1))]), _c('span', [_c('address-display', {
      attrs: {
        "address": i.account
      }
    })], 1)]), _c('span', [_vm._v(_vm._s(i.numberOfContributions))])]);
  }), 0)])])]), _c('div', {
    staticClass: "chart"
  }, [_c('h2', {
    staticClass: "title",
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v(" Top 5 contributors by DOT contributed "), _c('div', {
    staticClass: "share-wrap"
  }, [_c('img', {
    attrs: {
      "title": "Share to Twitter",
      "src": require("@/assets/images/twitter@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('twitter', '#share2', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  }), _c('img', {
    attrs: {
      "title": "Capture snapshot",
      "src": require("@/assets/images/download@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('download', '#share2', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  })])]), _c('div', {
    attrs: {
      "id": "share2"
    }
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    ref: "ContributorChart",
    staticClass: "transactionChart"
  })]), _c('div', {
    staticClass: "right"
  }, [_c('ul', _vm._l(_vm.getContributorsRankingListOfoverallContributionAmount, function (i, index) {
    return _c('li', {
      key: i.account
    }, [_c('div', [index < 3 ? _c('span', {
      staticClass: "rank"
    }, [_c('img', {
      attrs: {
        "src": require(`@/assets/images/crowdloan/kusama_detail_top${index + 1}.png`),
        "alt": ""
      }
    })]) : _c('span', {
      staticClass: "rank"
    }, [_vm._v(_vm._s(index + 1))]), _c('span', [_c('address-display', {
      attrs: {
        "address": i.account
      }
    })], 1)]), _c('el-tooltip', {
      attrs: {
        "content": _vm.formatedTokenValue(i.contributionAmount),
        "placement": "bottom"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.formatedCap(i.contributionAmount)))])])], 1);
  }), 0)])])]), _c('div', {
    staticClass: "history"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("History")]), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "share-wrap"
  }, [_c('img', {
    attrs: {
      "title": "Share to Twitter",
      "src": require("@/assets/images/twitter@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('twitter', '#share3', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  }), _c('img', {
    attrs: {
      "title": "Capture snapshot",
      "src": require("@/assets/images/download@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('download', '#share3', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  })]), _c('div', {
    ref: "historyTotal",
    staticClass: "chart-el",
    attrs: {
      "id": "share3"
    }
  })]), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticClass: "share-wrap"
  }, [_c('img', {
    attrs: {
      "title": "Share to Twitter",
      "src": require("@/assets/images/twitter@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('twitter', '#share4', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  }), _c('img', {
    attrs: {
      "title": "Capture snapshot",
      "src": require("@/assets/images/download@2x.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleShare('download', '#share4', 'Crowdloan Progress Updates! Check out our crowdloan data on @Web3Go');
      }
    }
  })]), _c('div', {
    ref: "historyIncrement",
    staticClass: "chart-el",
    attrs: {
      "id": "share4"
    }
  })])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('img', {
    attrs: {
      "src": require("@/assets/images/crowdloan/kusama_detail_raised.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Total raised")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "contributions"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/crowdloan/kusama_detail_contributions.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Number of contributions")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "contributions"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/crowdloan/kusama_detail_contributor.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Number of contributors")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('img', {
    attrs: {
      "src": require("@/assets/images/crowdloan/kusama_detail_progress.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Cap")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('img', {
    attrs: {
      "src": require("@/assets/images/crowdloan/kusama_detail_ration.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Progress")])]);
}];
export { render, staticRenderFns };