var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "insight-detail-page"
  }, [_c('div', {
    staticClass: "layout"
  }, [_c('div', {
    staticClass: "breadcrumb"
  }, [_c('span', {
    staticClass: "link hover-item",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" Insight ")]), _c('i', {
    staticClass: "el-icon-arrow-right"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$route.query.name))])]), _c('div', {
    staticClass: "content"
  }, [_c('iframe', {
    attrs: {
      "allowTransparency": "true",
      "width": "100%",
      "height": "100%",
      "src": _vm.$route.query.link,
      "frameborder": "0"
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };