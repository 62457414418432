var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "moonriver-detail-page"
  }, [_c('div', {
    staticClass: "common-back-title"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.address))])]), _c('div', {
    staticClass: "big-bg"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm.collectorRank))]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon1.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Rank")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.collectorData.bond, 2)))]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon2.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Self-Bonded(MOVR)")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.collectorData.TotalBonded, 2)))]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon3.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Total Bonded(MOVR)")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.rewardData.latestReward, 2)))]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon-reward.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Latest Reward(MOVR)")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.rewardData.totalReward, 2)))]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon-reward.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Total Reward(MOVR)")])])]), _c('div', {
    staticClass: "nftNav-wrap"
  }, [_c('div', {
    staticClass: "nftNav"
  }, _vm._l(_vm.navList, function (v) {
    return _c('span', {
      key: v.name,
      staticClass: "hover-item",
      class: {
        active: _vm.currentNav.name == v.name
      },
      on: {
        "click": function ($event) {
          return _vm.goToNav(v);
        }
      }
    }, [_vm._v(_vm._s(v.name))]);
  }), 0)]), _c('div', {
    staticClass: "component-wrap"
  }, [_c(_vm.currentNav.component, {
    ref: "component",
    tag: "component",
    attrs: {
      "collectorData": _vm.collectorData,
      "loading": _vm.loading
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };