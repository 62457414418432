var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "viz-table"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.tableData.slice((_vm.currentPage - 1) * _vm.pagesize, _vm.currentPage * _vm.pagesize)
    }
  }, _vm._l(_vm.headers, function (h) {
    return _c('el-table-column', {
      key: h,
      attrs: {
        "prop": h,
        "label": h
      }
    });
  }), 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.currentPage,
      "page-sizes": [10, 20, 30],
      "page-size": _vm.pagesize,
      "layout": "total, sizes, prev, pager, next, jumper",
      "total": parseInt(_vm.total)
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };