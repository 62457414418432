var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "paraChainAnalytics"
  }, [_c('div', {
    staticClass: "paraChainAnalytics-nav"
  }, [_c('span', {
    class: {
      act: _vm.tabType === 'KSM'
    },
    on: {
      "click": function ($event) {
        return _vm.switchTab('KSM');
      }
    }
  }, [_vm._v("KSM")]), _c('span', {
    class: {
      act: _vm.tabType === 'Contributors'
    },
    on: {
      "click": function ($event) {
        return _vm.switchTab('Contributors');
      }
    }
  }, [_vm._v("Contributors")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabType === 'KSM',
      expression: "tabType === 'KSM'"
    }]
  }, [_c('div', {
    staticClass: "paraChainAnalytics-line"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("Total KSM raised per round")]), _c('div', {
    ref: "KSM-line"
  })]), _c('div', {
    staticClass: "paraChainAnalytics-category"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("MAX / AVE / MIN KSM raised per round")]), _c('div', {
    ref: "KSM-category"
  })])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabType === 'Contributors',
      expression: "tabType === 'Contributors'"
    }]
  }, [_c('div', {
    staticClass: "paraChainAnalytics-line"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("Number of contributors per round")]), _c('div', {
    ref: "Contributors-line"
  })]), _c('div', {
    staticClass: "paraChainAnalytics-category"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("MAX / AVE / MIN campaign contributors per round")]), _c('div', {
    ref: "Contributors-category"
  })]), _c('div', {
    staticClass: "paraChainAnalytics-list"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("Top 10 Contributors")]), _c('div', {
    staticClass: "data-table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    key: Math.random(),
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading",
      "data": _vm.tableData
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Address",
      "prop": "crowdloanId",
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('identity-icon-plus', {
          attrs: {
            "addressInfo": {
              address: scope.row.account,
              addressDisplayCompact: true
            }
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "crowdloanCount",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "participations"
        }, [_vm._v(" " + _vm._s(scope.row.crowdloanCount) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Number of campaigns contributed",
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-icon-info"
  }, [_vm._v("Number")])])], 1)], 2), _c('el-table-column', {
    attrs: {
      "label": "Total KSM contributed",
      "prop": "Amount",
      "align": "left"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "amount"
        }, [_vm._v(" " + _vm._s(_vm.formatedCap(scope.row.totalAmount)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Total KSM contributed",
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-icon-info"
  }, [_vm._v("Total")])])], 1)], 2), _c('el-table-column', {
    attrs: {
      "label": "Max KSM contributed per campaign",
      "prop": "Max",
      "align": "left"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "max"
        }, [_vm._v(" " + _vm._s(_vm.formatedCap(scope.row.maxAmount)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Max KSM contributed per campaign",
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-icon-info"
  }, [_vm._v("Max")])])], 1)], 2), _c('el-table-column', {
    attrs: {
      "label": "Min KSM contributed per campaign",
      "prop": "Smallest",
      "align": "left"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "smallest"
        }, [_vm._v(" " + _vm._s(_vm.formatedCap(scope.row.minAmount)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('el-tooltip', {
    attrs: {
      "content": "Min KSM contributed per campaign",
      "placement": "top"
    }
  }, [_c('i', {
    staticClass: "el-icon-info"
  }, [_vm._v("Min")])])], 1)], 2), _c('el-table-column', {
    attrs: {
      "label": "Number of contributions",
      "prop": "Total",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "total"
        }, [_vm._v(" " + _vm._s(scope.row.contributionCount) + " ")])];
      }
    }])
  })], 1)], 1)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };