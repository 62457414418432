var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-dialog', {
    attrs: {
      "title": _vm.isDelegateMore ? 'DelegateMore to' : 'Delegate to',
      "visible": _vm.visible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "wallet-wrap"
  }, [_c('identity-icon-plus', {
    attrs: {
      "addressInfo": _vm.receiverAccount
    }
  })], 1), _c('div', {
    staticClass: "avaliable-token-wrap"
  }, [_c('div', {
    staticClass: "number"
  }, [_vm._v(" （avaliable " + _vm._s(_vm._f("roundNumber")(_vm.linkAccount.freeBalance, 2)) + " " + _vm._s(_vm.symbol) + "） ")])]), _c('div', {
    staticClass: "input-wrap"
  }, [_c('el-input-number', {
    attrs: {
      "min": _vm.minBondRequired,
      "max": Number(_vm.linkAccount.freeBalance),
      "label": "Input Num"
    },
    model: {
      value: _vm.num,
      callback: function ($$v) {
        _vm.num = $$v;
      },
      expression: "num"
    }
  }), _c('span', {
    staticClass: "symbol"
  }, [_vm._v(_vm._s(_vm.symbol))])], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('el-button', {
    attrs: {
      "loading": _vm.btnLoading,
      "type": "primary",
      "disabled": _vm.canNotConfirm
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("Confirm")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };