var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "profileDefi"
  }, [_c('div', {
    staticClass: "common-profile-sort"
  }, _vm._l(_vm.sortList, function (sort, index) {
    return _c('span', {
      key: sort.name,
      class: _vm.actSortIndex === index ? 'act' : '',
      on: {
        "click": function ($event) {
          return _vm.clickSort(index);
        }
      }
    }, [_vm._v(_vm._s(sort.name))]);
  }), 0), _c('div', {
    staticClass: "common-profile-component"
  }, [_c(_vm.actComponent, {
    tag: "Component",
    attrs: {
      "addressList": _vm.addressList
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };