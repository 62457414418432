var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "identity-icon-plus"
  }, [_c('div', {
    staticClass: "address-icon",
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.address);
      }
    }
  }, [_vm.isPolkadot ? _c('div', [_c('Identicon', {
    attrs: {
      "size": _vm.iconSize,
      "theme": 'polkadot',
      "value": _vm.address
    }
  })], 1) : _vm._e(), _vm.isEthereum ? _c('div', [_c('img', {
    staticClass: "eth-icon",
    style: {
      width: _vm.iconSize + 'px'
    },
    attrs: {
      "src": _vm.makeIcon4Ethereum(_vm.address),
      "alt": ""
    }
  })]) : _vm._e()]), !_vm.showIdentity ? _c('div', {
    staticClass: "address"
  }, [_vm.addressDisplayCompact ? _c('div', {
    staticClass: "address-display",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.address);
      }
    },
    slot: "content"
  }, [_vm._v(_vm._s(_vm.address))]), _c('div', {
    staticClass: "display",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" " + _vm._s(_vm.compactAddress(_vm.address)) + " ")])])], 1) : _c('div', {
    staticClass: "address-display",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" " + _vm._s(_vm.address) + " ")])]) : _vm._e(), _vm.showIdentity ? _c('div', {
    staticClass: "identity",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm.judgement_icon ? _c('div', {
    staticClass: "judgement",
    class: _vm.judgement_icon
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(_vm._s(_vm.judgement_desc))]), _c('span', {
    staticClass: "material-icons",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" " + _vm._s(_vm.judgement_icon) + " ")])])], 1) : _vm._e(), _c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.address);
      }
    },
    slot: "content"
  }, [_vm._v(_vm._s(_vm.address))]), _c('div', {
    staticClass: "display",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" " + _vm._s(_vm.identity.display) + " ")])])], 1) : _vm._e(), _c('div', {
    staticClass: "copy-action"
  }), _vm.showIdentityMoreInfo ? _c('div', {
    staticClass: "identity-more-info"
  }, [_vm.identity.legal ? _c('div', {
    staticClass: "item legal"
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.identity.legal);
      }
    },
    slot: "content"
  }, [_vm._v(" Legal name: " + _vm._s(_vm.identity.legal) + " ")]), _c('span', {
    staticClass: "material-icons",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" account_circle ")])])], 1) : _vm._e(), _vm.identity.email ? _c('div', {
    staticClass: "item email"
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.identity.email);
      }
    },
    slot: "content"
  }, [_vm._v(" Email: " + _vm._s(_vm.identity.email) + " ")]), _c('span', {
    staticClass: "material-icons",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" mail_outline ")])])], 1) : _vm._e(), _vm.identity.web ? _c('div', {
    staticClass: "item web"
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.identity.web);
      }
    },
    slot: "content"
  }, [_vm._v(" Web: " + _vm._s(_vm.identity.web) + " ")]), _c('span', {
    staticClass: "material-icons",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" language ")])])], 1) : _vm._e(), _vm.identity.twitter ? _c('div', {
    staticClass: "item twitter"
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.identity.twitter);
      }
    },
    slot: "content"
  }, [_vm._v(" Twitter: " + _vm._s(_vm.identity.twitter) + " ")]), _c('img', {
    style: {
      width: _vm.fontSize + 'px'
    },
    attrs: {
      "src": require("@/assets/images/twitter-logo.svg")
    }
  })])], 1) : _vm._e(), _vm.identity.accountPublicKey ? _c('div', {
    staticClass: "item public-key"
  }, [_c('el-tooltip', {
    attrs: {
      "effect": _vm.tooltipTheme,
      "placement": "top"
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    on: {
      "click": function ($event) {
        return _vm.copy(_vm.identity.accountPublicKey);
      }
    },
    slot: "content"
  }, [_vm._v(" Public key: " + _vm._s(_vm.identity.accountPublicKey) + " ")]), _c('span', {
    staticClass: "material-icons",
    style: {
      'font-size': _vm.fontSize + 'px'
    }
  }, [_vm._v(" key ")])])], 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "tips"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };