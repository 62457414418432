var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "profile-balance-page"
  }, [_c('div', {
    staticClass: "tag-wrap"
  }, [_c('div', {
    staticClass: "tag",
    class: {
      active: !_vm.currentTag
    },
    on: {
      "click": _vm.clearTag
    }
  }, [_vm._v(" All Chains ")]), _vm._l(_vm.balanceNavData, function (v, i) {
    return _c('div', {
      key: i,
      staticClass: "tag",
      class: {
        active: _vm.currentTag && v.network == _vm.currentTag.network
      },
      on: {
        "click": function ($event) {
          return _vm.changeTag(v);
        }
      }
    }, [_vm._v(" " + _vm._s(v.network) + " ")]);
  })], 2), _c('div', {
    staticClass: "table-wrap"
  }, [_c('div', {
    staticClass: "left-table"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.filterBalanceNavData.slice((_vm.tableParams1.pageIndex - 1) * _vm.tableParams1.pageSize, _vm.tableParams1.pageIndex * _vm.tableParams1.pageSize)
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Token",
      "width": "225"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "token-cell"
        }, [_c('img', {
          staticClass: "left",
          attrs: {
            "src": _vm.getIcon(scope.row),
            "alt": ""
          }
        }), _c('div', {
          staticClass: "right"
        }, [_c('div', {
          staticClass: "item-title"
        }, [_vm._v(_vm._s(scope.row.network))]), _c('div', {
          staticClass: "item-text"
        }, [_c('span', [_c('el-tooltip', {
          attrs: {
            "content": scope.row.value,
            "placement": "top"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.shorterAddress(scope.row.value)))])])], 1), _c('img', {
          staticClass: "copy",
          attrs: {
            "title": "copy",
            "src": require("@/assets/images/profile/copy.png"),
            "alt": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.copy(scope.row.value);
            }
          }
        })])])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Price"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.price === undefined ? _c('i', {
          staticClass: "el-icon-loading"
        }) : _c('div', [_vm._v("$ " + _vm._s(Number(scope.row.price).toFixed(2)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Balance"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.balance === undefined ? _c('i', {
          staticClass: "el-icon-loading"
        }) : _c('div', [_vm._v(" " + _vm._s(_vm._f("format2")(scope.row.balance)) + " " + _vm._s(scope.row.symbols[0]) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Value"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.totalPrice === undefined ? _c('i', {
          staticClass: "el-icon-loading"
        }) : _c('div', [_vm._v("$ " + _vm._s(_vm._f("format2")(scope.row.totalPrice)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "80",
      "label": " ",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm.currentRow && scope.row.value == _vm.currentRow.value && _vm.detailLoading ? _c('i', {
          staticClass: "el-icon-loading"
        }) : _c('span', {
          staticClass: "view hover-item",
          on: {
            "click": function ($event) {
              return _vm.rowClick(scope.row);
            }
          }
        }, [_vm.supportBalance(scope.row) ? _c('div', [_vm._v("view")]) : _vm._e()])];
      }
    }])
  })], 1)], 1)]), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('el-pagination', {
    attrs: {
      "layout": "prev, pager, next",
      "total": _vm.filterBalanceNavData.length,
      "current-page": _vm.tableParams1.pageIndex,
      "page-size": _vm.tableParams1.pageSize
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.tableParams1, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.tableParams1, "pageIndex", $event);
      },
      "current-change": _vm.tableCurrentChange1
    }
  })], 1)]), _c('el-dialog', {
    attrs: {
      "center": "",
      "title": _vm.currentRow && _vm.currentRow.value,
      "visible": _vm.visbile,
      "width": "600"
    },
    on: {
      "update:visible": function ($event) {
        _vm.visbile = $event;
      }
    }
  }, [_c('div', {
    staticClass: "right-table"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.detailLoading,
      expression: "detailLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.detailData.slice((_vm.tableParams2.pageIndex - 1) * _vm.tableParams2.pageSize, _vm.tableParams2.pageIndex * _vm.tableParams2.pageSize)
    }
  }, [_c('el-table-column', {
    attrs: {
      "width": "190"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "detail-from"
        }, [_c('img', {
          staticClass: "left",
          attrs: {
            "src": _vm.getIcon(_vm.currentRow),
            "alt": ""
          }
        }), _c('div', {
          staticClass: "right"
        }, [_c('div', {
          staticClass: "item-title"
        }, [_vm._v(" " + _vm._s(_vm.$moment(scope.row.timestamp).format("YYYY-MM-DD HH:mm")) + " ")]), _c('div', {
          staticClass: "item-text"
        }, [_c('span', [_c('el-tooltip', {
          attrs: {
            "content": scope.row.fromAccountId || scope.row.accountId,
            "placement": "top"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.shorterAddress(scope.row.fromAccountId || scope.row.accountId)))])])], 1)])])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "30",
      "class-name": "icon-cell"
    }
  }, [[_c('img', {
    staticClass: "trans",
    attrs: {
      "src": require("@/assets/images/profile/trans.png"),
      "alt": ""
    }
  })]], 2), _c('el-table-column', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "detail-to"
        }, [_c('div', {
          staticClass: "item-title"
        }, [_vm._v(" " + _vm._s(scope.row.type) + " ")]), _c('div', {
          staticClass: "item-text"
        }, [_c('span', [_c('el-tooltip', {
          attrs: {
            "content": scope.row.toAccountId,
            "placement": "top"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.shorterAddress(scope.row.toAccountId)))])])], 1)])])];
      }
    }])
  }), _c('el-table-column', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.balanceChange ? _c('div', {
          staticClass: "num-change-wrap"
        }, [_c('img', {
          staticClass: "left",
          attrs: {
            "src": _vm.getIcon(_vm.currentRow),
            "alt": ""
          }
        }), _vm.isAdd(scope.row) ? _c('span', {
          staticClass: "blue"
        }, [_vm._v("+" + _vm._s(_vm._f("formatBalanceWithDecimal")(scope.row.balanceChange, _vm.getDecimal())))]) : _c('span', {
          staticClass: "red"
        }, [_vm._v("-" + _vm._s(_vm._f("formatBalanceWithDecimal")(scope.row.balanceChange, _vm.getDecimal())))])]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_vm.currentRow ? _c('el-pagination', {
    attrs: {
      "layout": "prev, pager, next",
      "total": _vm.detailData.length,
      "current-page": _vm.tableParams2.pageIndex,
      "page-size": _vm.tableParams1.pageSize
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.tableParams2, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.tableParams2, "pageIndex", $event);
      }
    }
  }) : _vm._e()], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };