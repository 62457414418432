var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "component-wrap"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "info"
  }, _vm._l(_vm.numData, function (v, i) {
    return _c('div', {
      key: i,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "num"
    }, [v.value !== undefined ? _c('countTo', {
      attrs: {
        "startVal": 0,
        "endVal": v.value,
        "duration": 3000
      }
    }) : _c('span', [_vm._v("--")])], 1), _c('div', {
      staticClass: "label"
    }, [_vm._v(" " + _vm._s(v.display)), _c('el-tooltip', {
      attrs: {
        "placement": "top",
        "trigger": "hover"
      }
    }, [_c('div', {
      staticClass: "tooltip-300px",
      attrs: {
        "slot": "content"
      },
      slot: "content"
    }, [_c('span', {
      domProps: {
        "innerHTML": _vm._s(v.comment)
      }
    })]), _c('i', {
      staticClass: "el-icon-warning-outline"
    })])], 1)]);
  }), 0)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "unique-address",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/85ccc88c-5b1c-447a-ac0b-80113dee9b7d?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "500",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "active-address",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/aa3cac4f-c4b2-4716-9f8b-2efee8814809?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "500",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-layers",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/7c834069-ee41-4288-8aa7-524cf07bc815",
      "frameborder": "0",
      "width": "100%",
      "height": "500",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-category",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/3a8b602a-2219-4aaf-b462-ec6c11b4015c",
      "frameborder": "0",
      "width": "100%",
      "height": "650",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-daily-trend",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/573abf00-5a78-4ade-aa86-e354f6b41d28?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "600",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "tx-by-daily-stack",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/9881f737-b690-4fab-b126-89ee28d6e895?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "700",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "gross-inflation-breakout",
    attrs: {
      "src": "https://mb.web3go.xyz/public/question/d577f146-fed2-43f7-9e1e-644994e87c52?date=past30days",
      "frameborder": "0",
      "width": "100%",
      "height": "700",
      "allowtransparency": ""
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('iframe', {
    ref: "dapp-activity",
    attrs: {
      "src": "https://analytix.web3go.xyz/public/dashboard/a2da093b-723c-4ea9-9e2d-f72dd49f3e57",
      "frameborder": "0",
      "width": "100%",
      "height": "700",
      "allowtransparency": ""
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };