var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "big-title"
  }, [_vm._v("Moonbeam Overview")]), _c('div', {
    staticClass: "sub-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/worldIcon.png"),
      "alt": ""
    }
  }), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.jumpUrl('https://moonbeam.network/');
      }
    }
  }, [_vm._v("https://moonbeam.network/")])]), _vm._m(1)]), _c('div', {
    staticClass: "tab-content"
  }, [_c('Overview')], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fixed-right-menu"
  }, [_c('a', {
    staticClass: "link hover-item",
    attrs: {
      "target": "blank",
      "href": "https://web3go.gitbook.io/moonscan-smart-contract-label-for-projects/"
    }
  }, [_vm._v("Wanna label your contracts?")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "text"
  }, [_vm._v(" Made by the Web3Go team (Moonbeam Community Enthusiasts) We are early Moonbeam supporters and our mission is to provide open data analytics for the Moonbeam community. We started with a Moonbeam Collator Dashboard ( "), _c('a', {
    staticClass: "link",
    attrs: {
      "target": "blank",
      "href": "https://app.web3go.xyz/#/Moonbeam"
    }
  }, [_vm._v("https://app.web3go.xyz/#/Moonbeam")]), _vm._v(" )and have been working over the past year on more dashboards to provide greater visibility into what is happening on Moonbeam (both EVM and substrate). We will continue to refine our dashboards and add more information. Please reach out if you have suggestions or questions. ")]);
}];
export { render, staticRenderFns };