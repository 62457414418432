var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content parachain-manage"
  }, [_c('div', {
    staticClass: "parachain-type"
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.changeParaChainType
    },
    model: {
      value: _vm.parachainType,
      callback: function ($$v) {
        _vm.parachainType = $$v;
      },
      expression: "parachainType"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "Polkadot"
    }
  }), _c('el-radio-button', {
    attrs: {
      "label": "Kusama"
    }
  })], 1)], 1), _c('div', {
    staticClass: "top-operation"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.handleNew();
      }
    }
  }, [_vm._v("New Parachain")])], 1), _c('div', {}, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "id",
      "label": "id",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "paraId",
      "label": "paraId",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "projectName",
      "label": "projectName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "icon"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "project-icon"
        }, [_c('img', {
          attrs: {
            "src": '/static/parachain-icon/' + scope.row.icon
          }
        }), _c('div', [_vm._v(_vm._s(scope.row.icon))])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "operation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "type": "default",
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Edit")])];
      }
    }])
  })], 1)], 1), _c('el-dialog', {
    attrs: {
      "title": _vm.dialogTitle,
      "visible": _vm.dialogVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("paraId")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    attrs: {
      "disabled": _vm.dialogTitle == 'Edit'
    },
    model: {
      value: _vm.currentRow.paraId,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "paraId", $$v);
      },
      expression: "currentRow.paraId"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("projectName")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.projectName,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "projectName", $$v);
      },
      expression: "currentRow.projectName"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("icon name")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.icon,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "icon", $$v);
      },
      expression: "currentRow.icon"
    }
  })], 1)])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onSave();
      }
    }
  }, [_vm._v("Save")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };