var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content moonriver-page"
  }, [_c('div', {
    staticClass: "common-back-title"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("Moonbeam")]), !_vm.freeBalance ? _c('div', {
    staticClass: "link-btn-wrap"
  }, [!_vm.linkLoading ? _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/images/wallet-icon.png"),
      "alt": ""
    }
  }) : _vm._e(), _c('el-button', {
    staticClass: "link-btn",
    attrs: {
      "loading": _vm.linkLoading
    },
    on: {
      "click": _vm.handleLinkAccount
    }
  }, [_vm._v("Connect Wallet")])], 1) : _c('div', {
    staticClass: "wallet-wrap"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/images/moonriver/wallet-login-icon-moonbeam.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "number"
  }, [_c('el-tooltip', {
    attrs: {
      "content": _vm.linkAccount.address,
      "placement": "top"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.shotFilter(_vm.linkAccount.address)))])]), _vm._v(" （" + _vm._s(this.freeBalance) + " GLMR） ")], 1), _c('div', [_c('el-popover', {
    attrs: {
      "placement": "bottom",
      "width": "400",
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "popover-subscribe"
  }, [_c('div', {
    staticClass: "subscribe-address"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("My Wallet address:")]), _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.linkAccountSubscribeData.subscribe_address) + " ")])]), _c('div', {
    staticClass: "subscribe-auto-notify-at-my-stake"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Auto Notify Config:")]), _c('div', {
    staticClass: "content"
  }, [_c('el-switch', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "active-value": _vm.auto_notify_at_my_stake_active,
      "inactive-value": _vm.auto_notify_at_my_stake_inactive,
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
      "active-text": _vm.linkAccountSubscribeData.auto_notify_at_my_stake == 1 ? 'Auto notify my stake' : 'Ignore my stake'
    },
    on: {
      "change": function ($event) {
        return _vm.updateAutoNotify();
      }
    },
    model: {
      value: _vm.linkAccountSubscribeData.auto_notify_at_my_stake,
      callback: function ($$v) {
        _vm.$set(_vm.linkAccountSubscribeData, "auto_notify_at_my_stake", $$v);
      },
      expression: "linkAccountSubscribeData.auto_notify_at_my_stake"
    }
  })], 1)]), _vm.linkAccountSubscribeData.subscribe_email ? _c('div', {
    staticClass: "subscribe-email"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("My Email account:")]), _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.linkAccountSubscribeData.subscribe_email) + " "), _c('div', {
    staticClass: "subscribe-email-clear",
    on: {
      "click": function ($event) {
        _vm.linkAccountSubscribeData.subscribe_email = '';
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-remove-outline"
  })])])]) : _vm._e(), _vm.linkAccountSubscribeData.watched_address && _vm.linkAccountSubscribeData.watched_address.length > 0 ? _c('div', {
    staticClass: "watched-address"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Watched Addresses:")]), _vm._l(_vm.linkAccountSubscribeData.watched_address, function (wa) {
    return _c('div', {
      key: wa,
      staticClass: "content watched-address-item"
    }, [_vm._v(" " + _vm._s(wa) + " "), _c('div', {
      staticClass: "watched-address-remove",
      on: {
        "click": function ($event) {
          return _vm.unsubscribe({
            id: wa
          });
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-remove-outline"
    })])]);
  }), _c('div', {
    staticClass: "clear-all-subscribe"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.clearSubscribe();
      }
    }
  }, [_vm._v("Clear All")])])], 2) : _vm._e()]), _c('i', {
    staticClass: "el-icon-bell",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1)])]), _c('div', {
    staticClass: "big-bg"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "info-item"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon3.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Round Index")]), _c('div', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.roundInfo.current))])])]), _c('div', {
    staticClass: "info-item"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon1.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Block Number")]), _c('div', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.blockNumber))])])]), _c('div', {
    staticClass: "info-item"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon2.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Round Progress")]), _c('div', {
    staticClass: "number"
  }, [_vm._v(" " + _vm._s(_vm.blockNumber - _vm.roundInfo.first) + "/" + _vm._s(_vm.roundInfo.length) + " ")])]), _c('el-progress', {
    staticClass: "circle",
    attrs: {
      "type": "circle",
      "width": 50,
      "color": "#38CB98",
      "stroke-width": 10,
      "stroke-linecap": "butt",
      "percentage": _vm.getInfoPercentage,
      "show-text": false
    }
  })], 1)]), _c('el-tabs', {
    staticClass: "big-tabs",
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "Stake Simulation",
      "name": "1"
    }
  }), _c('el-tab-pane', {
    attrs: {
      "label": "My Stake",
      "name": "2"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTab == 1,
      expression: "activeTab == 1"
    }],
    staticClass: "tab-content tab-content1"
  }, [_c('div', {
    staticClass: "select-wrap"
  }, [_c('span', [_vm._v("Calculate Avg Blocks By ")]), _c('el-select', {
    attrs: {
      "collapse-tags": ""
    },
    on: {
      "change": _vm.changeSelect
    },
    model: {
      value: _vm.roundsPickedByDropdown,
      callback: function ($$v) {
        _vm.roundsPickedByDropdown = $$v;
      },
      expression: "roundsPickedByDropdown"
    }
  }, _vm._l(_vm.roundsDropdown, function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1), _c('span', [_vm._v("Round")])], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "data": _vm.onePageTableData
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Rank",
      "width": "90"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (scope) {
        return [_c('div', [_vm._v(" Rank "), _c('el-tooltip', {
          attrs: {
            "placement": "top",
            "trigger": "hover"
          }
        }, [_c('div', {
          staticClass: "tooltip-300px",
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" Current Rank by total stake of collator. "), _c('br'), _c('br'), _vm._v(" The green icon means the current collator was choosen and produce blocks in current round. ")]), _c('i', {
          staticClass: "el-icon-warning-outline"
        })])], 1)];
      }
    }, {
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "rank-icon",
          class: {
            'active-block-producer': scope.row.activeBlockProducer
          }
        }, [_vm._v(" " + _vm._s(scope.row.rankIndex + 1) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "250",
      "label": "Collator"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "icon-cell"
        }, [_c('identity-icon-plus', {
          attrs: {
            "addressInfo": {
              address: scope.row.id,
              addressDisplayCompact: true,
              isEthereum: true,
              fontSize: 16
            }
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.turnActionPage(scope);
            }
          }
        })], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Self Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getSelfStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "150",
      "label": "Delegator Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getNominatorStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Total Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getTotalStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Total Reward"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(scope.row.totalReward, 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Min Bond",
      "prop": "minBond",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (scope) {
        return [_c('div', [_vm._v(" Min Bond "), _c('el-tooltip', {
          attrs: {
            "placement": "top",
            "trigger": "hover"
          }
        }, [_c('div', {
          staticClass: "tooltip-300px",
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" Min Bond means the minimum bond amount to join the collator. ")]), _c('i', {
          staticClass: "el-icon-warning-outline"
        })])], 1)];
      }
    }, {
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(scope.row.minBond, 0)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Avg Blocks",
      "prop": "averageBlocks"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (scope) {
        return [_c('div', [_vm._v(" Avg Blocks "), _c('el-tooltip', {
          attrs: {
            "placement": "top",
            "trigger": "hover"
          }
        }, [_c('div', {
          staticClass: "tooltip-300px",
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" Average Blocks in past 10 round which has been rewarded ( round " + _vm._s(_vm.startRoundIndex4AverageBlocksCalculation) + " - " + _vm._s(_vm.endRoundIndex) + " ). "), _c('br'), _c('br')]), _c('i', {
          staticClass: "el-icon-warning-outline"
        })])], 1)];
      }
    }, {
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(scope.row.averageBlocks, 1)))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Current Blocks",
      "prop": "currentBlocks"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (scope) {
        return [_c('div', [_vm._v(" Current Blocks "), _c('el-tooltip', {
          attrs: {
            "placement": "top",
            "trigger": "hover"
          }
        }, [_c('div', {
          staticClass: "tooltip-300px",
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" Blocks produced in the current round " + _vm._s(_vm.currentRoundIndex) + ". "), _c('br'), _c('br')]), _c('i', {
          staticClass: "el-icon-warning-outline"
        })])], 1)];
      }
    }, {
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(scope.row.currentBlocks))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "APR",
      "prop": "apr",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (scope) {
        return [_c('div', [_vm._v(" APR "), _c('el-tooltip', {
          attrs: {
            "placement": "top",
            "trigger": "hover"
          }
        }, [_c('div', {
          staticClass: "tooltip-300px",
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" APR means the annualized income pledged to the current collator."), _c('br'), _c('br'), _c('br'), _vm._v(" APR Formula = 0.00001388888888888889 * total_supply * avg_blocks_per_round / collator_counted_stake * 100% ")]), _c('i', {
          staticClass: "el-icon-warning-outline"
        })])], 1)];
      }
    }, {
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(scope.row.apr, 2)) + "%")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "270",
      "prop": "name",
      "label": "Rewards(Last 10 rounds)"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          ref: 'tableChart' + scope.row.id,
          staticClass: "table-chart"
        })];
      }
    }])
  }), _c('el-table-column', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "div-operation"
        }, [_c('span', {
          ref: 'simulateBtn' + scope.row.id,
          staticClass: "table-operation-span",
          on: {
            "click": function ($event) {
              return _vm.showDetail(scope.$index, scope.row);
            }
          }
        }, [_c('i', {
          staticClass: "el-icon-data-line"
        }), _vm._v("Simulate")]), _c('el-tooltip', {
          attrs: {
            "effect": "light",
            "placement": "bottom"
          }
        }, [_c('div', {
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" Add current collator into watch list with specified email,"), _c('br'), _vm._v(" you'll recieve notification email when the rank reach the end of max collators."), _c('br'), _vm._v("current thresold is the last 10%. ")]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showSubscribe(scope.row),
            expression: "showSubscribe(scope.row)"
          }],
          staticClass: "subscribe",
          on: {
            "click": function ($event) {
              return _vm.subscribe(scope.row);
            }
          }
        }, [_c('i', {
          staticClass: "el-icon-circle-plus-outline"
        })])]), _c('el-tooltip', {
          attrs: {
            "effect": "light",
            "placement": "bottom"
          }
        }, [_c('div', {
          attrs: {
            "slot": "content"
          },
          slot: "content"
        }, [_vm._v(" remove current collator from watch list. ")]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.alreadySubscribed(scope.row),
            expression: "alreadySubscribed(scope.row)"
          }],
          staticClass: "subscribe-already",
          on: {
            "click": function ($event) {
              return _vm.unsubscribe(scope.row);
            }
          }
        }, [_c('i', {
          staticClass: "el-icon-remove-outline"
        })])])], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next,sizes",
      "total": _vm.tableData.length,
      "current-page": _vm.pageIndex,
      "page-size": _vm.pageSize,
      "page-sizes": [100]
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.pageIndex = $event;
      },
      "update:current-page": function ($event) {
        _vm.pageIndex = $event;
      },
      "size-change": _vm.generateTableChart,
      "current-change": _vm.generateTableChart,
      "update:pageSize": function ($event) {
        _vm.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _vm.pageSize = $event;
      }
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeTab == 2,
      expression: "activeTab == 2"
    }],
    staticClass: "tab-content tab-content2"
  }, [_c('div', {
    staticClass: "search-wrap"
  }, [_c('el-input', {
    staticClass: "search-input",
    attrs: {
      "placeholder": "Wallet Account",
      "prefix-icon": "el-icon-search"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getMyStackList.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchAccount,
      callback: function ($$v) {
        _vm.searchAccount = $$v;
      },
      expression: "searchAccount"
    }
  }), _c('div', {
    staticClass: "search-account-btn",
    on: {
      "click": function ($event) {
        return _vm.getMyStackList();
      }
    }
  }, [_vm._v("search")]), _c('div', {
    staticClass: "reset-account-btn",
    on: {
      "click": function ($event) {
        return _vm.resetAccountFilter();
      }
    }
  }, [_vm._v(" reset ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.searchAccount,
      expression: "searchAccount"
    }],
    staticClass: "reward-history-btn",
    on: {
      "click": function ($event) {
        return _vm.turnDelegatorActionPage(_vm.searchAccount);
      }
    }
  }, [_vm._v(" reward history ")])], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "my-stack-table",
    attrs: {
      "data": _vm.tableData2
    }
  }, [_c('el-table-column', {
    attrs: {
      "width": "250",
      "label": "Collator"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "icon-cell"
        }, [_c('identity-icon-plus', {
          attrs: {
            "addressInfo": {
              address: scope.row.id,
              addressDisplayCompact: true,
              isEthereum: true,
              fontSize: 16
            }
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.turnActionPage(scope);
            }
          }
        })], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Rank"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "rank-icon"
        }, [_c('span', {
          staticClass: "text"
        }, [_vm._v(_vm._s(_vm.getCollectorRank(scope.row)))]), _c('el-popover', {
          staticClass: "icon-wrap",
          attrs: {
            "placement": "bottom",
            "trigger": "click"
          }
        }, [_c('div', {
          staticClass: "popover-content"
        }, [_c('div', {
          staticClass: "top"
        }, [_c('div', {
          staticClass: "item"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Max Collators is " + _vm._s(_vm.maxCollector))]), _vm.getCollectorRank(scope.row) >= parseInt(_vm.maxCollector * 0.9) ? _c('span', {
          staticClass: "title"
        }, [_c('br'), _vm._v(" Current Rank is below " + _vm._s(parseInt(_vm.maxCollector * 0.9)) + " (last 10%)"), _c('br')]) : _vm._e()]), _c('div', {
          staticClass: "item"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Total Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getTotalStake(scope.row), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Previous Rank Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getPreviousTotalStake(scope.row), 2)) + " GLMR")])])]), _c('div', {
          staticClass: "bottom"
        }, [_c('div', {
          staticClass: "item"
        }, [_c('div', {
          staticClass: "item-left"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Rank " + _vm._s(parseInt(_vm.maxCollector * 0.9)) + " Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getTotalStakeByRank(parseInt(_vm.maxCollector * 0.9)), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item-right"
        }, [_c('el-progress', {
          attrs: {
            "text-inside": true,
            "stroke-width": 16,
            "percentage": _vm.getCollectPercentByRank(parseInt(_vm.maxCollector * 0.9))
          }
        })], 1)]), _c('div', {
          staticClass: "item"
        }, [_c('div', {
          staticClass: "item-left"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Rank " + _vm._s(parseInt(_vm.maxCollector * 0.6)) + " Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getTotalStakeByRank(parseInt(_vm.maxCollector * 0.6)), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item-right"
        }, [_c('el-progress', {
          attrs: {
            "text-inside": true,
            "stroke-width": 16,
            "percentage": _vm.getCollectPercentByRank(parseInt(_vm.maxCollector * 0.6))
          }
        })], 1)])])]), _vm.getCollectorRank(scope.row) < parseInt(_vm.maxCollector * 0.9) ? _c('img', {
          staticClass: "tooltip-icon safe",
          attrs: {
            "slot": "reference",
            "src": require("@/assets/images/moonriver/stake-safe.png"),
            "alt": ""
          },
          slot: "reference"
        }) : _vm._e(), _vm.getCollectorRank(scope.row) >= parseInt(_vm.maxCollector * 0.9) ? _c('img', {
          staticClass: "tooltip-icon",
          attrs: {
            "slot": "reference",
            "src": require("@/assets/images/moonriver/stake-danger.png"),
            "alt": ""
          },
          slot: "reference"
        }) : _vm._e()])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Self Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getSelfStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "150",
      "label": "Nominator Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getNominatorStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Total Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getTotalStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "My Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getMyStake(scope.row), 2)) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "My Rank"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "rank-icon"
        }, [_c('span', {
          staticClass: "text"
        }, [_vm._v(_vm._s(_vm.getStakeRank(scope.row)))]), _c('el-popover', {
          staticClass: "icon-wrap",
          attrs: {
            "placement": "bottom",
            "trigger": "click"
          }
        }, [_c('div', {
          staticClass: "popover-content"
        }, [_c('div', {
          staticClass: "top"
        }, [_c('div', {
          staticClass: "item"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("My Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getMyStake(scope.row), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Previous Rank Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getPreviousSingleNominatorStake(scope.row), 2)) + " GLMR")])])]), _c('div', {
          staticClass: "bottom"
        }, [_c('div', {
          staticClass: "item"
        }, [_c('div', {
          staticClass: "item-left"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Rank " + _vm._s(_vm.maxNominator) + " Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getSingleNominatorStakeByRank(scope.row, _vm.maxNominator), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item-right"
        }, [_c('el-progress', {
          attrs: {
            "text-inside": true,
            "stroke-width": 16,
            "percentage": _vm.getSingleNominatorStakePercentByRank(scope.row, _vm.maxNominator)
          }
        })], 1)]), _c('div', {
          staticClass: "item"
        }, [_c('div', {
          staticClass: "item-left"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Rank " + _vm._s(parseInt(_vm.maxNominator * 0.9)) + " Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getSingleNominatorStakeByRank(scope.row, parseInt(_vm.maxNominator * 0.9)), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item-right"
        }, [_c('el-progress', {
          attrs: {
            "text-inside": true,
            "stroke-width": 16,
            "percentage": _vm.getSingleNominatorStakePercentByRank(scope.row, parseInt(_vm.maxNominator * 0.9))
          }
        })], 1)]), _c('div', {
          staticClass: "item"
        }, [_c('div', {
          staticClass: "item-left"
        }, [_c('span', {
          staticClass: "title"
        }, [_vm._v("Rank " + _vm._s(parseInt(_vm.maxNominator * 0.5)) + " Stake:")]), _c('span', {
          staticClass: "number"
        }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.getSingleNominatorStakeByRank(scope.row, parseInt(_vm.maxNominator * 0.5)), 2)) + " GLMR")])]), _c('div', {
          staticClass: "item-right"
        }, [_c('el-progress', {
          attrs: {
            "text-inside": true,
            "stroke-width": 16,
            "percentage": _vm.getSingleNominatorStakePercentByRank(scope.row, parseInt(_vm.maxNominator * 0.5))
          }
        })], 1)])])]), _vm.getStakeRank(scope.row) >= _vm.maxNominator - 10 ? _c('img', {
          staticClass: "tooltip-icon",
          attrs: {
            "slot": "reference",
            "src": require("@/assets/images/moonriver/stake-danger.png"),
            "alt": ""
          },
          slot: "reference"
        }) : _vm._e()])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "My Ratio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-progress', {
          attrs: {
            "text-inside": true,
            "stroke-width": 16,
            "percentage": _vm.getMyRatio(scope.row)
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "fixed-popover"
  }, [_c('el-popover', {
    ref: "popover",
    attrs: {
      "placement": "left",
      "popper-class": "simulate-popover"
    },
    on: {
      "hide": _vm.removeScrollEvent
    }
  }, [_vm.currentSimulate.id ? _c('div', {
    staticClass: "simulate-popover-content"
  }, [_c('div', {
    ref: 'detailChart' + _vm.currentSimulate.id,
    staticClass: "detail-chart"
  }), _c('div', {
    staticClass: "title statistic-title"
  }, [_c('span', [_vm._v("RPM Statistic")]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "tooltip-300px",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(" RPM, Rewards Per GLMR. To simplify the calculation, we define RPM as \"rewards per GLMR when nominating to the specific collator\" ")]), _c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('div', {
    staticClass: "statistic"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Min:")]), _c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.currentSimulate.mimRPM, 7)) + " GLMR")])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Max:")]), _c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.currentSimulate.maxRPM, 7)) + " GLMR")])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Average:")]), _c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.currentSimulate.averageRPM, 7)) + " GLMR")])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v(" RPM Valatility Score: ")]), _c('span', {
    staticClass: "yellow"
  }, [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.currentSimulate.standardDeviation, 8)))]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "tooltip-300px",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(" The volatility of rewards. We use standard deviation to indicate the volatility of rewards. The less the volatility is, the rewards of nominating this collator are relatively stable(according to the latest 10 rounds) ")]), _c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1)]), _c('div', {
    staticClass: "title"
  }, [_vm._v("Estimate Reward")]), _c('div', {
    staticClass: "input-wrap"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Stake")]), _c('el-input', {
    staticClass: "input",
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }), _c('span', {
    staticClass: "unit"
  }, [_vm._v("GLMR")]), _c('el-tooltip', {
    staticClass: "tip",
    attrs: {
      "placement": "top",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "tooltip-300px",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(" Input the quantity of GLMR to estimate the rewards in real-time. Estimated reward = Quantity * RPM, RPM is calculated according to the standard deviation ")]), _c('i', {
    staticClass: "el-icon-warning-outline"
  })]), _c('i', {
    staticClass: "el-icon-right"
  }), _c('span', {
    staticClass: "yellow"
  }, [_vm._v(_vm._s(_vm.getBoundaryReward(_vm.currentSimulate)))]), _c('span', {
    staticClass: "unit"
  }, [_vm._v("GLMR")])], 1), _c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v("Competitor ")]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "tooltip-300px",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(" The real-time data of GLMR staked by the nominators and the corresponding ranking are listed here ")]), _c('i', {
    staticClass: "el-icon-warning-outline"
  })])], 1), _c('div', {
    staticClass: "competitor-wrap"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item-top"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Current Rank:")]), _c('span', {
    staticClass: "number yellow"
  }, [_vm._v(_vm._s(_vm.getSimulateRank(_vm.currentSimulate)))])]), _c('div', {
    staticClass: "progress-wrap"
  }, [_c('el-progress', {
    attrs: {
      "text-inside": true,
      "stroke-width": 16,
      "percentage": _vm.getSumulatePercent(_vm.currentSimulate)
    }
  })], 1)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item-top"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Rank " + _vm._s(_vm.maxNominator) + " Stake:")]), _c('span', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getSingleNominatorStakeByRank(_vm.currentSimulate, parseInt(_vm.maxNominator))) + " GLMR")])]), _c('div', {
    staticClass: "progress-wrap"
  }, [_c('el-progress', {
    attrs: {
      "text-inside": true,
      "stroke-width": 16,
      "percentage": _vm.getSumulatePercentByRank(_vm.currentSimulate, _vm.maxNominator)
    }
  })], 1)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item-top"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Rank " + _vm._s(parseInt(_vm.maxNominator * 0.9)) + " Stake:")]), _c('span', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getSingleNominatorStakeByRank(_vm.currentSimulate, parseInt(_vm.maxNominator * 0.9))) + " GLMR")])]), _c('div', {
    staticClass: "progress-wrap"
  }, [_c('el-progress', {
    attrs: {
      "text-inside": true,
      "stroke-width": 16,
      "percentage": _vm.getSumulatePercentByRank(_vm.currentSimulate, parseInt(_vm.maxNominator * 0.9))
    }
  })], 1)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "item-top"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Rank " + _vm._s(parseInt(_vm.maxNominator * 0.5)) + " Stake:")]), _c('span', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.getSingleNominatorStakeByRank(_vm.currentSimulate, parseInt(_vm.maxNominator * 0.5))) + " GLMR")])]), _c('div', {
    staticClass: "progress-wrap"
  }, [_c('el-progress', {
    attrs: {
      "text-inside": true,
      "stroke-width": 16,
      "percentage": _vm.getSumulatePercentByRank(_vm.currentSimulate, parseInt(_vm.maxNominator * 0.5))
    }
  })], 1)])])]) : _vm._e(), _c('span', {
    staticClass: "table-operation-span",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('i', {
    staticClass: "el-icon-data-line"
  }), _vm._v("Simulate")])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };