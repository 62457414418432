var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content profile-defi-kraura"
  }, [_vm._m(0), _c('div', {
    staticClass: "cdp-list"
  }, [_c('div', {
    staticClass: "cdp-list-header"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 5
    }
  }, [_vm._v("Parachain")]), _c('el-col', {
    attrs: {
      "span": 5,
      "align": "right"
    }
  }, [_vm._v("Debt")]), _c('el-col', {
    attrs: {
      "span": 4,
      "align": "right"
    }
  }, [_vm._v("Collateral")]), _c('el-col', {
    attrs: {
      "span": 4,
      "align": "right"
    }
  }, [_vm._v("Ratio")]), _c('el-col', {
    staticClass: "statusCol",
    attrs: {
      "span": 3
    }
  }, [_vm._v("Status")])], 1)], 1), _c('div', {
    staticClass: "cdp-list-content"
  }, [_vm._l(_vm.loanPositionTableData, function (d) {
    return _c('div', {
      key: d.accountId,
      staticClass: "lp-row"
    }, [_c('el-row', [_c('el-col', {
      attrs: {
        "span": 5,
        "align": "left"
      }
    }, [_c('div', {
      staticClass: "accountId"
    }, [_c('img', {
      staticClass: "account-logo",
      attrs: {
        "src": require("@/assets/images/kusama-logo.png")
      }
    }), _c('address-display', {
      attrs: {
        "address": d.accountId,
        "isPrifile": true
      }
    })], 1)]), _c('el-col', {
      attrs: {
        "span": 5,
        "align": "right"
      }
    }, [_c('div', {
      staticClass: "debt"
    }, [_vm._v(_vm._s(d.debitFormat) + " KUSD")])]), _c('el-col', {
      attrs: {
        "span": 4,
        "align": "right"
      }
    }, [_c('div', {
      staticClass: "collateral"
    }, [_vm._v(" " + _vm._s(d.collateralFormat) + " KSM ")])]), _c('el-col', {
      attrs: {
        "span": 4,
        "align": "right"
      }
    }, [_c('div', {
      staticClass: "ratio",
      class: _vm.getShowColor(d)
    }, [_vm._v(" " + _vm._s(d.ratioPercentage) + " % ")])]), _c('el-col', {
      attrs: {
        "span": 4
      }
    }, [_c('div', {
      staticClass: "status",
      class: _vm.getShowColor(d)
    }, [_vm._v(" " + _vm._s(d.status) + " ")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('div', {
      staticClass: "view",
      on: {
        "click": function ($event) {
          return _vm.showLoanPositionDetail(d);
        }
      }
    }, [_vm._v(" view  "), _c('i', {
      staticClass: "el-icon-right"
    })])])], 1)], 1);
  }), !_vm.loanPositionTableData.length ? _c('div', {
    staticClass: "common-profile-table-nodata"
  }, [_vm._v(" No Data ")]) : _vm._e()], 2), _c('div', {
    staticClass: "cdp-list-pagination"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "current-page": _vm.query.pageIndex,
      "page-size": _vm.query.pageSize,
      "page-sizes": [10, 20, 50, 100],
      "total": _vm.totalCount
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:pageSize": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "update:page-size": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "common-profile-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/home/karura.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("Karura CDP")])]);
}];
export { render, staticRenderFns };