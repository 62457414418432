import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nft g-wrap"
  }, [_c('div', {
    staticClass: "nftHead"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/');
      }
    }
  }), _c('img', {
    staticClass: "nftHead-logo",
    attrs: {
      "src": require("../../assets/images/nft/logo.png"),
      "alt": ""
    }
  }), _vm._m(0)]), _c('div', {
    staticClass: "nftNav"
  }, [_c('span', {
    staticClass: "act"
  }, [_vm._v("Market Overview")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTMintMaster');
      }
    }
  }, [_vm._v("Mint Master")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTGraph');
      }
    }
  }, [_vm._v("NFT Graph")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTTimeMachine');
      }
    }
  }, [_vm._v("Time Machine")])]), _c('div', {
    staticClass: "nftNavCon"
  }, [_c('div', {
    staticClass: "nftNavConInfo"
  }, [_c('img', {
    staticClass: "nftNavConInfo-logo",
    attrs: {
      "src": require("../../assets/images/nft/logo.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "nftNavConInfo-con"
  }, [_c('h2', {
    staticClass: "nftNavConInfo-con-title"
  }, [_vm._v("Volume over time on RMRK")]), _c('p', {
    staticClass: "nftNavConInfo-con-des"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(_vm.collectionVolume)))]), _c('span', [_vm._v("KSM")])])])]), _c('div', {
    staticClass: "nftNavConList"
  }, [_c('div', {
    staticClass: "nftNavConList-head"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("NFT Market Overview")]), _c('el-tooltip', {
    attrs: {
      "effect": "dark",
      "placement": "top-start"
    }
  }, [_c('div', {
    staticClass: "tooltip-300px",
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_vm._v(" The latest market activity of all times. Please note that the max, avg, min price are in KSM and include listing and transfer price on RMRK. ")]), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/images/nft/info.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticStyle: {
      "float": "right"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "datetimerange",
      "range-separator": "-",
      "start-placeholder": "start date",
      "end-placeholder": "end date",
      "default-time": ['00:00:00', '23:59:59'],
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    on: {
      "change": _vm.loadCollectionStatistic
    },
    model: {
      value: _vm.timeRange4collectionStatistic,
      callback: function ($$v) {
        _vm.timeRange4collectionStatistic = $$v;
      },
      expression: "timeRange4collectionStatistic"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.nftNavConListLoading,
      expression: "nftNavConListLoading"
    }],
    staticClass: "nftNavConList-table",
    attrs: {
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading"
    }
  }, [_c('div', {
    staticClass: "nftNavConList-table-header"
  }, [_c('el-row', _vm._l(_vm.tableTitle, function (item, index) {
    return _c('el-col', {
      key: item.value,
      class: item.className,
      attrs: {
        "span": item.col,
        "align": item.align
      }
    }, [_vm._v(_vm._s(item.title) + " "), _c('div', {
      staticClass: "sort"
    }, [_c('p', [_c('span', {
      staticClass: "up",
      class: item.sort === 'ASC' ? 'act' : '',
      on: {
        "click": function ($event) {
          return _vm.getTopTradedCollectionsSort(item.value, 'ASC', index);
        }
      }
    }), _c('span', {
      staticClass: "down",
      class: item.sort === 'DESC' ? 'act' : '',
      on: {
        "click": function ($event) {
          return _vm.getTopTradedCollectionsSort(item.value, 'DESC', index);
        }
      }
    })])])]);
  }), 1)], 1), _c('div', {
    staticClass: "nftNavConList-table-content"
  }, [_c('div', {
    staticClass: "lp-row"
  }, _vm._l(_vm.listData, function (item) {
    return _c('el-row', {
      key: item.id
    }, [_c('el-col', {
      staticClass: "collection hover-item",
      attrs: {
        "span": _vm.tableTitle[0].col,
        "align": "left"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c('div', [_c('el-tooltip', {
      attrs: {
        "effect": "dark",
        "content": item.name,
        "placement": "top-start"
      }
    }, [_c('span', {
      staticClass: "collection_name"
    }, [_vm._v(_vm._s(item.name))])])], 1)]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[1].col
      }
    }, [_c('div', [_vm._v(" " + _vm._s(_vm._f("formatKUSAMA")(item.collection_volume)) + " ")])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[2].col
      }
    }, [_c('div', [_vm._v(_vm._s(item.collection_transactions))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[3].col
      }
    }, [_c('div', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(item.max_price_list)))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[4].col
      }
    }, [_c('div', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(item.avg_price_list)))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[5].col
      }
    }, [_c('div', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(item.min_price_list)))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[6].col
      }
    }, [_c('div', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(item.max_price)))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[7].col
      }
    }, [_c('div', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(item.avg_price)))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[8].col
      }
    }, [_c('div', [_vm._v(_vm._s(_vm._f("formatKUSAMA")(item.min_price)))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[9].col
      }
    }, [_c('div', [_vm._v(_vm._s(item.buyer_count))])]), _c('el-col', {
      attrs: {
        "span": _vm.tableTitle[10].col
      }
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.filterTimestamp_created_at(item.timestamp_created_at)) + " ")])])], 1);
  }), 1)]), _c('div', {
    staticClass: "nftNavConList-table-pagination"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next,sizes,jumper",
      "current-page": _vm.query.pageIndex,
      "page-size": _vm.query.pageSize,
      "page-sizes": [10, 20, 50, 100],
      "total": _vm.totalCount
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.query, "pageIndex", $event);
      },
      "update:pageSize": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "update:page-size": function ($event) {
        return _vm.$set(_vm.query, "pageSize", $event);
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)])]), _c('div', {
    staticClass: "nftNavConTop"
  }, [_c('div', {
    staticClass: "nftNavConTop-head"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Top 5 Collections")]), _c('el-tooltip', {
    attrs: {
      "effect": "dark",
      "content": "Sorted by volume traded in the last 7 days. ",
      "placement": "top-start"
    }
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/images/nft/info.png"),
      "alt": ""
    }
  })])], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.nftNavConTopLoading,
      expression: "nftNavConTopLoading"
    }],
    attrs: {
      "id": "nftNavConTop-line",
      "element-loading-text": "loading ",
      "element-loading-spinner": "el-icon-loading"
    }
  })])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nftHead-con"
  }, [_c('h2', {
    staticClass: "nftHead-con-title"
  }, [_vm._v("NFT Garden")]), _c('p', {
    staticClass: "nftHead-con-des"
  }, [_vm._v("By Web3go Official Team")])]);
}];
export { render, staticRenderFns };