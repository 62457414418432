var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout"
  }, [_c('div', {
    staticClass: "back-title"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.name))]), _c('span', {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.$moment(_vm.infoData.timestamp_created_at).format("YYYY-MM-DD")))])]), _c('div', {
    staticClass: "nftNav-wrap"
  }, [_c('div', {
    staticClass: "nftNav"
  }, [_c('span', {
    staticClass: "active hover-item"
  }, [_vm._v("Overview")]), _c('span', {
    staticClass: "hover-item",
    on: {
      "click": _vm.goToBreakdown
    }
  }, [_vm._v("Breakdown")])])]), _c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v("Min Price (Buy/List)")]), _c('div', {
    staticClass: "middle"
  }, [_vm._v(" " + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.min_price)) + "/" + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.min_price_list)) + " KSM ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v("Average Price (Buy/List)")]), _c('div', {
    staticClass: "middle"
  }, [_vm._v(" " + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.avg_price)) + "/" + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.avg_price_list)) + " KSM ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v("Max Price (Buy/List)")]), _c('div', {
    staticClass: "middle"
  }, [_vm._v(" " + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.max_price)) + "/" + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.max_price_list)) + " KSM ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v("Volume")]), _c('div', {
    staticClass: "middle"
  }, [_vm._v(" " + _vm._s(_vm._f("formatKUSAMA")(_vm.infoData.collection_volume)) + " KSM ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v("Transactions")]), _c('div', {
    staticClass: "middle"
  }, [_vm._v(_vm._s(_vm.infoData.collection_transactions))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._v("Unique Buyers")]), _c('div', {
    staticClass: "middle"
  }, [_vm._v(_vm._s(_vm.infoData.buyer_count))])])])]), _c('div', {
    staticClass: "chart-wrap"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chart1Loading,
      expression: "chart1Loading"
    }],
    staticClass: "chart-item",
    attrs: {
      "id": "chart1"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" Average Price & Volume ")]), _c('img', {
    staticClass: "code-img",
    attrs: {
      "src": require("@/assets/images/code.svg"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.generateIframe('chart1');
      }
    }
  })]), _c('div', {
    ref: "chart1",
    staticClass: "chart chart1"
  })]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chart2Loading,
      expression: "chart2Loading"
    }],
    staticClass: "chart-item",
    attrs: {
      "id": "chart2"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" Price Range ")]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover",
      "content": "View the min,max and avg price of sales on any given day."
    }
  }, [_c('img', {
    staticClass: "info-icon",
    attrs: {
      "src": require("@/assets/images/info-icon.png")
    }
  })]), _c('img', {
    staticClass: "code-img",
    attrs: {
      "src": require("@/assets/images/code.svg"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.generateIframe('chart2');
      }
    }
  })], 1), _c('div', {
    ref: "chart2",
    staticClass: "chart chart2"
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chart3Loading,
      expression: "chart3Loading"
    }],
    staticClass: "chart-item",
    attrs: {
      "id": "chart3"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" Individual Transactions ")]), _c('el-tooltip', {
    attrs: {
      "placement": "top",
      "trigger": "hover",
      "content": "Tracks the latest individual transactions. Click into them to see the NFT detail. "
    }
  }, [_c('img', {
    staticClass: "info-icon",
    attrs: {
      "src": require("@/assets/images/info-icon.png")
    }
  })]), _c('img', {
    staticClass: "code-img",
    attrs: {
      "src": require("@/assets/images/code.svg"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.generateIframe('chart3');
      }
    }
  })], 1), _c('div', {
    ref: "chart3",
    staticClass: "chart chart3"
  })]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chart4Loading,
      expression: "chart4Loading"
    }],
    staticClass: "chart-item",
    attrs: {
      "id": "chart4"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(" Transactions & Buyers ")]), _c('el-tooltip', {
    attrs: {
      "popper-class": "nft-detail-page-popover",
      "placement": "top",
      "trigger": "hover",
      "content": "Track the amount of transactions and unique buyers on any given day."
    }
  }, [_c('img', {
    staticClass: "info-icon",
    attrs: {
      "src": require("@/assets/images/info-icon.png")
    }
  })]), _c('img', {
    staticClass: "code-img",
    attrs: {
      "src": require("@/assets/images/code.svg"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.generateIframe('chart4');
      }
    }
  })], 1), _c('div', {
    ref: "chart4",
    staticClass: "chart chart4"
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };