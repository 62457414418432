import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nft g-wrap"
  }, [_c('div', {
    staticClass: "nftHead"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/');
      }
    }
  }), _c('img', {
    staticClass: "nftHead-logo",
    attrs: {
      "src": require("../../assets/images/nft/logo.png"),
      "alt": ""
    }
  }), _vm._m(0)]), _c('div', {
    staticClass: "nftNav"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTProfiler');
      }
    }
  }, [_vm._v("Market Overview")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTMintMaster');
      }
    }
  }, [_vm._v("Mint Master")]), _c('span', {
    staticClass: "act"
  }, [_vm._v("NFT Graph")]), _c('span', {
    on: {
      "click": function ($event) {
        return _vm.goto('NFTTimeMachine');
      }
    }
  }, [_vm._v("Time Machine")])]), _c('div', {
    staticClass: "statistic"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.statisticInfo.total_count_with_resource))]), _vm._m(1), _c('div', {
    staticClass: "desc"
  }, [_vm._v("Number of NFTs with resource")])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.statisticInfo.total_count_with_children))]), _vm._m(2), _c('div', {
    staticClass: "desc"
  }, [_vm._v("Number of NFTs with children")])])]), _c('div', {
    staticClass: "type-title"
  }, [_vm._v(" NFT Tree God Mode "), _c('div', {
    staticClass: "search-nft"
  }, [_c('el-input', {
    staticClass: "input-with-select",
    attrs: {
      "placeholder": "search by nft name"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.doSearch(true);
      }
    },
    model: {
      value: _vm.queryParams.nft_name,
      callback: function ($$v) {
        _vm.$set(_vm.queryParams, "nft_name", $$v);
      },
      expression: "queryParams.nft_name"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append",
      "icon": "el-icon-search"
    },
    on: {
      "click": function ($event) {
        return _vm.doSearch(true);
      }
    },
    slot: "append"
  })], 1)], 1)]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "type-switch"
  }, [_c('el-tabs', {
    on: {
      "tab-click": function ($event) {
        return _vm.doSearch(true);
      }
    },
    model: {
      value: _vm.activeType,
      callback: function ($$v) {
        _vm.activeType = $$v;
      },
      expression: "activeType"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": "Resource",
      "name": "Resource"
    }
  }), _c('el-tab-pane', {
    attrs: {
      "label": "Children",
      "name": "Children"
    }
  })], 1)], 1), _c('div', {
    staticClass: "nft-list"
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.nftList, function (item, index) {
    return _c('div', {
      key: item.id,
      staticClass: "nft-list-item",
      class: {
        active: item.id == _vm.currentActiveNft.id
      },
      on: {
        "click": function ($event) {
          return _vm.setActiveRecord(index);
        }
      }
    }, [_c('el-tooltip', {
      staticClass: "item",
      attrs: {
        "effect": "dark",
        "content": item.id,
        "placement": "bottom",
        "open-delay": _vm.tooltipDelay
      }
    }, [_c('div', {
      staticClass: "nft-list-item-inner"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name || item.id))]), item.children && item.children.length ? _c('div', {
      staticClass: "nft-list-flag"
    }, [_c('div', {
      staticClass: "flag-circle"
    }), _vm._v(" " + _vm._s(item.children.length) + " ")]) : _vm._e(), item.resources && item.resources.length ? _c('div', {
      staticClass: "nft-list-flag"
    }, [_c('div', {
      staticClass: "flag-diamond"
    }), _vm._v(" " + _vm._s(item.resources.length) + " ")]) : _vm._e()])])], 1);
  }), 0), _c('div', {
    staticClass: "pagination"
  }, [_c('el-pagination', {
    attrs: {
      "hide-on-single-page": true,
      "total": _vm.totalCount,
      "layout": "prev, pager, next",
      "current-page": _vm.queryParams.pageIndex
    },
    on: {
      "update:currentPage": function ($event) {
        return _vm.$set(_vm.queryParams, "pageIndex", $event);
      },
      "update:current-page": function ($event) {
        return _vm.$set(_vm.queryParams, "pageIndex", $event);
      },
      "current-change": _vm.handlePageChange
    }
  })], 1)])]), _vm._m(3)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nftHead-con"
  }, [_c('h2', {
    staticClass: "nftHead-con-title"
  }, [_vm._v("NFT Garden")]), _c('p', {
    staticClass: "nftHead-con-des"
  }, [_vm._v("By Web3go Official Team")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("./../../assets/images/nft/icon-multi-resources.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("./../../assets/images/nft/icon-children.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "chart"
  }, [_c('div', {
    attrs: {
      "id": "tree-chart"
    }
  })]), _c('div', {
    staticClass: "nft-flag-legend"
  }, [_c('div', {
    staticClass: "nft-list-flag"
  }, [_c('div', {
    staticClass: "flag-circle"
  }), _vm._v(" NFT ")]), _c('div', {
    staticClass: "nft-list-flag"
  }, [_c('div', {
    staticClass: "flag-diamond"
  }), _vm._v(" Resource ")])])]);
}];
export { render, staticRenderFns };