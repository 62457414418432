var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "insight-page"
  }, [_c('div', {
    staticClass: "layout"
  }, [_c('div', {
    staticClass: "type-list"
  }, [_c('div', {
    staticClass: "item",
    class: {
      active: !_vm.type
    },
    on: {
      "click": function ($event) {
        _vm.type = null;
      }
    }
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/images/allInsight.png"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("All Chain")])]), _vm._l(_vm.typeStrList, function (v, i) {
    return _c('div', {
      key: i,
      staticClass: "item",
      class: {
        active: _vm.type == v
      },
      on: {
        "click": function ($event) {
          _vm.type = v;
        }
      }
    }, [_c('img', {
      staticClass: "icon",
      attrs: {
        "src": _vm.getTypeIcon(v),
        "alt": ""
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(v))])]);
  })], 2), _c('div', {
    staticClass: "card-wrap"
  }, _vm._l(_vm.currentTypeList, function (v) {
    return _c('div', {
      key: v.id,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "card hover-item",
      on: {
        "click": function ($event) {
          return _vm.goDetail(v);
        }
      }
    }, [_c('div', {
      staticClass: "c-title"
    }, [_c('img', {
      attrs: {
        "src": 'static/parachain-icon/' + v.icon,
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(v.name))])]), _c('img', {
      staticClass: "img",
      attrs: {
        "src": v.snapshot,
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "info"
    }, [_c('span', [_vm._v(_vm._s(v.created))]), _c('div', {
      staticClass: "right"
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/home/eye.png"),
        "alt": ""
      }
    }), _c('span', [_vm._v(_vm._s(v.viewCount))])])])]);
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };