var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "login-wrap"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('img', {
    staticClass: "left-bg",
    attrs: {
      "src": require("@/assets/images/login/bg1.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "login-main"
  }, [_c('div', {
    staticClass: "l-logo"
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("New Password")]), _c('el-form', {
    ref: "form",
    staticClass: "login-form",
    attrs: {
      "label-position": "top",
      "model": _vm.form,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "password",
      "label": "Password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": _vm.input1Show ? 'text' : 'password',
      "auto-complete": "off"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }, [_vm.input1Show ? _c('i', {
    staticClass: "el-input__icon el-icon-lock",
    attrs: {
      "slot": "suffix"
    },
    on: {
      "click": _vm.changeInput1Show
    },
    slot: "suffix"
  }) : _c('i', {
    staticClass: "el-input__icon el-icon-minus",
    attrs: {
      "slot": "suffix"
    },
    on: {
      "click": _vm.changeInput1Show
    },
    slot: "suffix"
  })])], 1), _c('el-form-item', {
    attrs: {
      "prop": "rePassword",
      "label": "Password Confirm"
    }
  }, [_c('el-input', {
    attrs: {
      "type": _vm.input2Show ? 'text' : 'password',
      "auto-complete": "off"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.send.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.rePassword,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rePassword", $$v);
      },
      expression: "form.rePassword"
    }
  }, [_vm.input2Show ? _c('i', {
    staticClass: "el-input__icon el-icon-lock",
    attrs: {
      "slot": "suffix"
    },
    on: {
      "click": _vm.changeInput2Show
    },
    slot: "suffix"
  }) : _c('i', {
    staticClass: "el-input__icon el-icon-minus",
    attrs: {
      "slot": "suffix"
    },
    on: {
      "click": _vm.changeInput2Show
    },
    slot: "suffix"
  })])], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('el-button', {
    attrs: {
      "size": "big",
      "type": "primary"
    },
    nativeOn: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.send.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "login-txt"
  }, [_vm._v("change Password")])])], 1)], 1)], 1)]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "right"
  }, [_c('img', {
    staticClass: "bg",
    attrs: {
      "src": require("@/assets/images/login/bg3.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "bg2"
  })]);
}];
export { render, staticRenderFns };