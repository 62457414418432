var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "viz-component viz-barchart"
  }, [_vm.options.editSupport ? _c('div', {
    staticClass: "viz-options"
  }, [_c('div', {
    staticClass: "config"
  }, [_vm._m(0), _c('div', {
    staticClass: "section-item"
  }, [_c('div', {
    staticClass: "section-item-desc"
  }, [_vm._v("Title")]), _c('div', {
    staticClass: "section-item-value"
  }, [_c('el-input', {
    attrs: {
      "placeholder": ""
    },
    model: {
      value: _vm.config.chartTitle,
      callback: function ($$v) {
        _vm.$set(_vm.config, "chartTitle", $$v);
      },
      expression: "config.chartTitle"
    }
  })], 1)]), _vm._m(1), _c('div', {
    staticClass: "section-item"
  }, [_c('div', {
    staticClass: "section-item-desc"
  }, [_vm._v("X column")]), _c('div', {
    staticClass: "section-item-value"
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "choose field"
    },
    model: {
      value: _vm.config.xColumn,
      callback: function ($$v) {
        _vm.$set(_vm.config, "xColumn", $$v);
      },
      expression: "config.xColumn"
    }
  }, _vm._l(_vm.fields, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1)]), _c('div', {
    staticClass: "section-item"
  }, [_c('div', {
    staticClass: "section-item-desc"
  }, [_vm._v("Y column")]), _c('div', {
    staticClass: "section-item-value"
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "choose field"
    },
    model: {
      value: _vm.config.yColumn,
      callback: function ($$v) {
        _vm.$set(_vm.config, "yColumn", $$v);
      },
      expression: "config.yColumn"
    }
  }, _vm._l(_vm.fields, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1)])]), _c('div', {
    staticClass: "generate"
  }, [_c('el-button', {
    staticClass: "btn-chart-generate",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.generateChart
    }
  }, [_vm._v("Run")])], 1)]) : _vm._e(), _c('div', {
    staticClass: "viz-chart"
  }, [_c('div', {
    ref: "chart",
    staticStyle: {
      "width": "100%",
      "height": "100%"
    }
  })])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section-title"
  }, [_c('i', {
    staticClass: "el-icon-caret-right"
  }), _vm._v("Display options ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section-title"
  }, [_c('i', {
    staticClass: "el-icon-caret-right"
  }), _vm._v("Result Data ")]);
}];
export { render, staticRenderFns };