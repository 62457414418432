import { render, staticRenderFns } from "./ERC20.vue?vue&type=template&id=3e37e7ee&scoped=true&"
import script from "./ERC20.vue?vue&type=script&lang=js&"
export * from "./ERC20.vue?vue&type=script&lang=js&"
import style0 from "./ERC20.vue?vue&type=style&index=0&id=3e37e7ee&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e37e7ee",
  null
  
)

export default component.exports