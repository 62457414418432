var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "table-wrap"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "data": _vm.collectorData.allNominators.slice((_vm.pageIndex - 1) * _vm.pageSize, _vm.pageIndex * _vm.pageSize)
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Rank",
      "width": "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "rank-icon",
          class: {
            first: scope.row.rank == 1,
            second: scope.row.rank == 2,
            third: scope.row.rank == 3
          }
        }, [_vm._v(" " + _vm._s(scope.row.rank) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "roundindex",
      "label": "Delegator"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "icon-cell"
        }, [_c('img', {
          staticClass: "icon",
          attrs: {
            "src": _vm.makeBlockie(scope.row.owner),
            "alt": ""
          }
        }), _c('el-tooltip', {
          attrs: {
            "content": scope.row.owner,
            "placement": "top"
          }
        }, [_c('span', {
          staticClass: "span",
          on: {
            "click": function ($event) {
              return _vm.turnDelegatorActionPage(scope.row);
            }
          }
        }, [_vm._v(_vm._s(_vm.shotFilter(scope.row.owner)))])])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Stake"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(scope.row.amount) + " GLMR")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Percent"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(scope.row.percent) + " %")])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next,sizes,jumper",
      "total": _vm.collectorData.allNominators.length,
      "current-page": _vm.pageIndex,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 20, 50, 100]
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.pageIndex = $event;
      },
      "update:current-page": function ($event) {
        _vm.pageIndex = $event;
      },
      "update:pageSize": function ($event) {
        _vm.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _vm.pageSize = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };