var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-container",
    class: {
      'no-head': _vm.$route.meta.noHead
    },
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "logo",
    on: {
      "click": _vm.goHome
    }
  }, [_c('a', {
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.logoImg,
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "common-header"
  }, [_c('div', {
    staticClass: "nav-left"
  }, [_c('ul', {
    staticClass: "nav-list"
  }, _vm._l(_vm.navLeft, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "nav-item",
      class: {
        active: item.name === _vm.actNav.name || item.name == _vm.$route.meta.parent
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.clickNav(item, index);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(item.name))])]);
  }), 0)]), _c('div', {
    staticClass: "nav-right"
  }, [_c('div', {
    staticClass: "search"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Search"
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.jumpToProfileIndex.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inputValue = $event.target.value;
      }
    }
  })]), _c('ul', {
    staticClass: "nav-list"
  }, _vm._l(_vm.navRight, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "nav-item"
    }, [_c('a', {
      attrs: {
        "href": item.url,
        "target": "blank"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0), _c('span', {
    staticClass: "create button",
    on: {
      "click": function ($event) {
        return _vm.buildNewQuery();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.plusImg,
      "alt": ""
    }
  }), _vm._v("Create")]), !_vm.userInfo ? _c('div', {
    staticClass: "login-btn",
    on: {
      "click": _vm.toLogin
    }
  }, [_vm._v("login")]) : _c('div', [_c('el-dropdown', {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "avatar-wrapper"
  }, [_c('img', {
    staticClass: "user-avatar",
    attrs: {
      "src": _vm.userInfo.avatar || require('@/assets/images/avatar.png')
    }
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.toMyInfo.apply(null, arguments);
      }
    }
  }, [_vm._v("My Info")]), _c('el-dropdown-item', {
    attrs: {
      "divided": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("Logout")])])], 1)], 1), _c('span', {
    staticClass: "username"
  }, [_vm._v(_vm._s(_vm.userInfo.name))])], 1)])])]), _vm.isShowComing ? _c('div', {
    staticClass: "coming"
  }, [_c('img', {
    attrs: {
      "src": require("./assets/images/coming.png"),
      "alt": ""
    }
  }), _c('p', [_vm._v("Coming soon...")])]) : _c('router-view', {
    staticClass: "common-view"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };