import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content address-tag-manage"
  }, [_c('div', {
    staticClass: "top-operation"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handleNew();
      }
    }
  }, [_vm._v("New insight")])], 1), _c('div', {}, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.tableData
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "icon",
      "width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('img', {
          staticClass: "icon",
          attrs: {
            "src": 'static/parachain-icon/' + scope.row.icon,
            "alt": ""
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "dashboard_id",
      "label": "dashboard_id"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "description",
      "label": "description"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "operation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "size": "mini"
          },
          on: {
            "click": function ($event) {
              return _vm.handleEdit(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Edit")]), _c('el-button', {
          attrs: {
            "size": "mini",
            "type": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.handleDelete(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Remove")])];
      }
    }])
  })], 1)], 1), _c('el-dialog', {
    attrs: {
      "title": _vm.dialogTitle,
      "visible": _vm.dialogVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("name")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.name,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "name", $$v);
      },
      expression: "currentRow.name"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("dashboard_id")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.dashboard_id,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "dashboard_id", $$v);
      },
      expression: "currentRow.dashboard_id"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("type")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "type of dashboard, sample as : Moonbeam, Polkadot"
    },
    model: {
      value: _vm.currentRow.type,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "type", $$v);
      },
      expression: "currentRow.type"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("description")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.description,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "description", $$v);
      },
      expression: "currentRow.description"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("tags")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.tags,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "tags", $$v);
      },
      expression: "currentRow.tags"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("icon")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.icon,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "icon", $$v);
      },
      expression: "currentRow.icon"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("snapshot")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.snapshot,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "snapshot", $$v);
      },
      expression: "currentRow.snapshot"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "desc"
  }, [_vm._v("link")]), _c('div', {
    staticClass: "value"
  }, [_c('el-input', {
    model: {
      value: _vm.currentRow.link,
      callback: function ($$v) {
        _vm.$set(_vm.currentRow, "link", $$v);
      },
      expression: "currentRow.link"
    }
  })], 1)])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.onSave();
      }
    }
  }, [_vm._v("Save")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };