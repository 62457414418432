var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "moonriver-detail-page"
  }, [_c('div', {
    staticClass: "common-back-title"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.address))])]), _c('div', {
    staticClass: "big-bg"
  }, [_c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "info-left"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": _vm.makeBlockie(_vm.address),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "right"
  }, [_vm._m(0), _c('div', {
    staticClass: "copy-wrap"
  }, [_c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.address))]), _c('img', {
    staticClass: "copy hover-item",
    attrs: {
      "title": "copy",
      "src": require("@/assets/images/profile/copy.png"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$utils.copy(_vm.address);
      }
    }
  })])])]), _c('div', {
    staticClass: "split"
  }), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.bonded, 2)) + " MOVR")]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon2.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Total Bonded")])]), _c('div', {
    staticClass: "split"
  }), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.rewardData.latestReward, 2)) + " MOVR")]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon-reward.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Latest Reward")])]), _c('div', {
    staticClass: "split"
  }), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("roundNumber")(_vm.rewardData.totalReward, 2)) + " MOVR")]), _c('img', {
    attrs: {
      "src": require("@/assets/images/moonriver/icon-reward.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "label"
  }, [_vm._v("Total Reward")])])]), _c('div', {
    staticClass: "nftNav-wrap"
  }, [_c('div', {
    staticClass: "nftNav"
  }, _vm._l(_vm.navList, function (v) {
    return _c('span', {
      key: v.name,
      staticClass: "hover-item",
      class: {
        active: _vm.currentNav.name == v.name
      },
      on: {
        "click": function ($event) {
          return _vm.goToNav(v);
        }
      }
    }, [_vm._v(_vm._s(v.name))]);
  }), 0)]), _c('div', {
    staticClass: "component-wrap"
  }, [_c(_vm.currentNav.component, {
    ref: "component",
    tag: "component"
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "title"
  }, [_c('span', [_vm._v("Delegator")])]);
}];
export { render, staticRenderFns };