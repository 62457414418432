import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout nft-breakdown-page"
  }, [_c('div', {
    staticClass: "back-title"
  }, [_c('i', {
    staticClass: "el-icon-back",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.$route.query.back
        });
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.name))]), _c('span', {
    staticClass: "time"
  }, [_vm._v("Collection created at : " + _vm._s(_vm.$moment(_vm.infoData.timestampCreatedAt).format("YYYY-MM-DD")))])]), _c('div', {
    staticClass: "nftNav-wrap"
  }, [_c('div', {
    staticClass: "nftNav"
  }, [_c('span', {
    staticClass: "hover-item",
    on: {
      "click": _vm.goToOverview
    }
  }, [_vm._v("Overview")]), _c('span', {
    staticClass: "hover-item active"
  }, [_vm._v("Breakdown")])])]), _c('div', {
    staticClass: "info-wrap"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Collection Name")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.name))])]), _c('div', {
    staticClass: "item"
  }, [_c('span', {
    staticClass: "label"
  }, [_vm._v("Collection ID")]), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.infoData.id))])])]), _c('div', {
    staticClass: "item-list-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('span', {
    staticClass: "left-text"
  }, [_vm._v(" Item List ")]), _c('el-input', {
    attrs: {
      "placeholder": "Search name"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchKey,
      callback: function ($$v) {
        _vm.searchKey = $$v;
      },
      expression: "searchKey"
    }
  }, [_c('img', {
    staticClass: "search-icon",
    attrs: {
      "slot": "prefix",
      "src": require("@/assets/images/nft/search.png"),
      "alt": ""
    },
    slot: "prefix"
  })])], 1), _c('el-table', {
    staticClass: "table",
    attrs: {
      "data": _vm.tableData,
      "loading": _vm.tableLoading
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Item ID"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-tooltip', {
          attrs: {
            "effect": "dark",
            "content": scope.row.id,
            "placement": "top"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.shorterAddress(scope.row.id)) + " ")])])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          attrs: {
            "title": scope.row.name
          }
        }, [_vm._v(_vm._s(_vm.shorterName(scope.row.name)) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Price(KSM)",
      "sortable": "custom",
      "prop": "price"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('div', {
          staticClass: "price-wrap"
        }, [_c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("formatKUSAMA")(scope.row.price)) + " ")]), _c('el-progress', {
          attrs: {
            "percentage": _vm.getPercent(scope.row),
            "show-text": false
          }
        })], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Contract date",
      "sortable": "custom",
      "prop": "timestampCreatedAt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', [_vm._v(_vm._s(_vm.$moment(scope.row.timestampCreatedAt).format("YYYY-MM-DD")) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "",
      "width": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('span', {
          staticClass: "hover-item view",
          on: {
            "click": function ($event) {
              return _vm.clickItem(scope.row);
            }
          }
        }, [_vm._v("View ")])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next,sizes,jumper",
      "current-page": _vm.pageIndex,
      "page-size": _vm.pageSize,
      "page-sizes": [10, 20, 50, 100],
      "total": _vm.totalCount
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.pageIndex = $event;
      },
      "update:current-page": function ($event) {
        _vm.pageIndex = $event;
      },
      "update:pageSize": function ($event) {
        _vm.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _vm.pageSize = $event;
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };